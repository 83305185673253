define("webapp/locales/fr/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.entryBannerImageNew": "Spécifiez une image principale pour votre saisie.",
    "account.ui.profileBannerImageNew": "Définissez une image principale pour votre profil.",
    "account.ui.register": "s'inscrire",
    "account.ui.personate": "Usurper l'identité d'un utilisateur",
    "account.ui.registeredAs": "Vous êtes actuellement inscrit en tant que",
    "account.ui.userNameNotEditableTooltip": "Votre nom d'utilisateur. Vous pouvez modifier le nom d'utilisateur saisi dans les paramètres. Important : Après le changement, vous devez vous reconnecter avec le nouveau nom d'utilisateur.",
    "accountTypes.aussteller": "exponent",
    "accountTypes.gastgeber": "gastherène",
    "accountTypes.veranstalter": "organisme",
    "application.beta.infotext": "Cette fonction est affichée uniquement et n'est pas disponible dans la version bêta.",
    "application.breadcrumbsHome": "Thuis",
    "application.month": "par maand",
    "application.newsletter.alreadySubscribed": "Vous êtes déjà inscrit au {{list}}. Bulletins d'information. Vous avez accès aux abonnements à la newsletter dans votre compte.",
    "application.newsletter.alreadySubscribedNoList": "Vous êtes déjà abonné à la newsletter",
    "application.newsletter.goToList": "Mailinglijst",
    "application.newsletter.subscribeButton": "Saisir l'adresse e-mail pour s'abonner à la newsletter",
    "application.newsletter.subscribeButtonMobile": "E-mail",
    "application.newsletter.noAccess": "Vous ne pouvez pas vous abonner à la newsletter proposée avec votre permission.",
    "application.newsletter.subscribe": "ondertekenenen",
    "application.newsletter.subscribeList": "Vous vous abonnez à cette newsletter :",
    "application.newsletter.subscribeThanks": "Merci de vous inscrire à la newsletter. Vous recevrez un e-mail de confirmation pour l'opt-in avant l'activation.",
    "application.newsletter.subscribeWithAccount": "Inscrivez-vous auprès de votre utilisateur actuellement connecté.",
    "application.placeholderImageText": "sans illustration",
    "application.searchAllGroups": "en totaal",
    "application.searchAllRegions": "en tous lieux",
    "application.ui.access": "oproep",
    "application.ui.action": "actie",
    "application.ui.addDocument": "Ajouter un document",
    "application.ui.addRelation": "Ajouter une connexion",
    "application.ui.addImage": "Ajouter une image",
    "application.ui.addImages": "Ajouter des photos",
    "application.ui.addMedia": "Ajouter des photos/vidéos",
    "application.ui.addVideo": "Ajouter une vidéo",
    "application.ui.advancedEdit": "Uitgebreid",
    "application.ui.all": "tous",
    "application.ui.alphabetical": "Par ordre alphabétique",
    "application.ui.at": "à",
    "application.ui.back": "retour",
    "application.ui.buy": "acheter",
    "application.ui.certificate": "chatouillé",
    "application.ui.confirmDeleteEntry": "Supprimer l'entrée {{title}} irrévocablement ?",
    "application.ui.count": "quantité",
    "application.ui.currentlyActive": "Porte à refoulement en boucle",
    "application.ui.deleteEntry": "embrasures de portes",
    "application.ui.discardUnsavedChanges": "En jetant les changements et en continuant avec",
    "application.ui.discardUnsavedChangesMobile": "jetant",
    "application.ui.distance": "onhechting",
    "application.ui.distant": "arraché",
    "application.ui.dreampoints": "Droompunten",
    "application.ui.dreampointsAllDreams": "Rêves de vos entrées",
    "application.ui.dreampointsDonate": "Points de rêve à donner",
    "application.ui.dreampointsTooltip": "Vous pouvez collecter des Dreampoints avec votre soumission",
    "application.ui.edit": "wijzigen",
    "application.ui.editDescription": "Modifier la description",
    "application.ui.editEntry": "travailler",
    "application.ui.editEvent": "Appliquer l'événement",
    "application.ui.editImage": "Modifier une image",
    "application.ui.editPoi": "bewerken Locatie",
    "application.ui.editProduct": "Appliquer au produit",
    "application.ui.editProfile": "Modifier mon profil",
    "application.ui.editShownProfile": "Modifier mon profil d'annonce",
    "application.ui.entriesFocus": "Geselecteerde items in Focus :",
    "application.ui.entriesLatest": "Récemment inzendingen in Focus :",
    "application.ui.for": "in ruil voor",
    "application.ui.fullView": "algemene visie",
    "application.ui.gallery": "images",
    "application.ui.galleryImages": "à la galerie...",
    "application.ui.gavePoints": "vous a donné des {{points}} points.",
    "application.ui.goToEntry": "Appels",
    "application.ui.goToEvent": "Bel evenement",
    "application.ui.goToPoi": "belplaats",
    "application.ui.goToProduct": "Voir le produit",
    "application.ui.hasBeen": "ce que",
    "application.ui.here": "poète",
    "application.ui.invalidEmbed": "Cette URL ne peut pas être intégrée.",
    "application.ui.isActive": "en service",
    "application.ui.isNotActive": "inactif",
    "application.ui.knowMore": "En savoir plus sur {{about}}",
    "application.ui.language.de": "Deutsch",
    "application.ui.language.en": "English",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "Italiano",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "Langue actuellement sélectionnée",
    "application.ui.language.switchTo": "Changer la langue vers ",
    "application.ui.mailBodyPre": "Bonjour, j'ai trouvé quelque chose d'intéressant :",
    "application.ui.mailSubject": "J'ai trouvé un lien intéressant",
    "application.ui.mandatory": "veld promis",
    "application.ui.memberSince": "Les paupières sont",
    "application.ui.mobileRotate": "Rotation pour affichage plein écran",
    "application.ui.more": "vélivoleur",
    "application.ui.name": "Nom",
    "application.ui.navigateOneLevelUp": "Retour au dossier parent",
    "application.ui.new": "Nieuw",
    "application.ui.newEntry": "Créer un nouvel article",
    "application.ui.newEvent": "Créer un nouvel événement d'entreprise",
    "application.ui.newPoi": "Créer un nouvel emplacement",
    "application.ui.newProduct": "Créer un nouveau produit",
    "application.ui.noEntry": "Vous n'avez pas encore effectué de saisie. Créez maintenant la première entrée",
    "application.ui.noEntryProfile": "n'a pas encore fait d'entrée.",
    "application.ui.noEvent": "Vous n'avez pas encore créé de formation d'entreprise. Créez votre premier événement maintenant",
    "application.ui.noPoi": "Ils n'ont pas encore créé de site. Créez votre premier emplacement maintenant",
    "application.ui.noJob": "Vous n'avez pas encore créé de job. Créer le premier job maintenant...",
    "application.ui.noProduct": "Vous n'avez pas encore fabriqué de produit. Créez votre premier produit maintenant",
    "application.ui.notEditable": "ne peut pas être modifié",
    "application.ui.nothingFound": "Nous n'avons trouvé aucun résultat pour cette recherche.",
    "application.ui.offer": "offerandes",
    "application.ui.only": "voies",
    "application.ui.openDocument": "Bestand ouvre",
    "application.ui.or": "ou",
    "application.ui.playVideo": "Vidéo en cours de lecture",
    "application.ui.readMore": "Lees meer",
    "application.ui.region": "région",
    "application.ui.relevance": "Pertinent",
    "application.ui.reset": "Réinitialiser",
    "application.ui.routingDescription": "Comment nous trouver",
    "application.ui.saveShownProfile": "Enregistrer les changements de profil",
    "application.ui.saveUnsavedChanges": "Opslaan",
    "application.ui.showAllEntries": "Afficher tous les résultats",
    "application.ui.showAllSearch": "Afficher tous les résultats",
    "application.ui.showMoreSearch": "Afficher plus de résultats de recherche",
    "application.ui.resetSearch": "Annuler la sélection et chercher à nouveau",
    "application.ui.sort": "triage",
    "application.ui.stockPhotoLinks": "Liens vers les bases de données d'images",
    "application.ui.suitableFor": "Recommandé pour",
    "application.ui.total": "totaal",
    "application.ui.totalDreampoints": "Rêves de toutes vos entrées",
    "application.ui.trySearch": "Voulez-vous essayer d'autres termes de recherche, tels que",
    "application.ui.type": "dactylographier",
    "application.ui.unsavedChanges": "Modifications non sauvegardées",
    "application.ui.updated": "bijgewerkt",
    "application.ui.uploadedFileFor": "Geüpload bestand voor :",
    "application.ui.userName": "inlognaam",
    "application.ui.visibleForAll": "Visible à tous les utilisateurs",
    "application.ui.visibleForFollower": "Visible pour les adeptes",
    "application.ui.visibleForJoiner": "Visible pour le menuisier",
    "application.ui.visibleForNone": "Visible à personne",
    "application.ui.visibleForSupporter": "Visible pour les supporters",
    "application.ui.writeUs": "écrivez-nous",
    "application.ui.years": "Anées",
    "application.ui.yearsFew": "Peu d'années",
    "application.widgets.highestRatedEntries": "La plupart des rêves",
    "application.widgets.inFocus": "En point de mire",
    "application.widgets.newestEntries": "Les dernières entrées",
    "blog.ui.category": "Catégorie Catégorie",
    "blog.ui.categoryHeading": "Uit de categorie",
    "blog.ui.lastUpdated": "Dernière mise à jour le",
    "blog.ui.openSinglePost": "Vers la vue d'article",
    "blog.ui.publishedIn": "Publié dans",
    "blog.ui.subCategories": "sous-catégorieën",
    "blog.ui.written": "hurlant",
    "blog.ui.writtenBy": "Écrit par",
    "buttons.alreadyMeToo": "Je suis content que vous rêviez de cette entrée, aussi.",
    "buttons.alreadyMeTooMobile": "Je suis content que vous rêviez de cette entrée, aussi.",
    "buttons.cancel": "brisé",
    "buttons.createAccount": "Ouvrez un nouveau compte EntryerAccount dans",
    "buttons.delete": "embrasures de portes",
    "buttons.deleteFavorite": "volume des rivets",
    "buttons.deleteLike": "Je n'aime plus ça.",
    "buttons.meToo": "C'est ce que je veux faire.",
    "buttons.newPassword": "Demander un nouveau mot de passe",
    "buttons.ok": "Ok",
    "buttons.ready": "Claar",
    "buttons.save": "Enregistrement des modifications",
    "buttons.saveMobile": "Opslaan",
    "buttons.selectThis": "Sélectionner cette option",
    "buttons.tryAgain": "Essayez à nouveau",
    "category.ui.hideCategories": "Masquer les sous-catégories",
    "category.ui.oneLevelUp": "Retour à la catégorie supérieure",
    "category.ui.showCategories": "Afficher les sous-catégories",
    "categoryFields.additionalGauge": "",
    "categoryFields.additionalWeight": "",
    "categoryFields.attachmentWidth": "Largeur de l'accessoire",
    "categoryFields.clutch": "Embrayage",
    "categoryFields.companyName": "Nom de l'entreprise",
    "categoryFields.dealerInfo": "Renseignements sur le concessionnaire",
    "categoryFields.diameter": "Diamètre des pneus",
    "categoryFields.displayNetPrice": "Prix net",
    "categoryFields.electricalPower": "Puissance électrique",
    "categoryFields.engine": "Moteur",
    "categoryFields.engineDescription": "Equipement moteur",
    "categoryFields.ERPname": "Nom dans l'ERP",
    "categoryFields.horsePower": "Puissance",
    "categoryFields.netWidth": "Jauge sans pneus",
    "categoryFields.packingUnit": "Unité d'emballage",
    "categoryFields.PTO": "Prise de Force",
    "categoryFields.relativeGauge": "Jauge supplémentaire",
    "categoryFields.sectionWidth": "",
    "categoryFields.vendorAcronym": "Désignation abrégée",
    "categoryFields.volume": "Volume",
    "categoryFields.weight": "Poids",
    "categoryFields.wheelLock": "Blocage des roues à l'arrêt",
    "comments.blockComments": "Les commentaires sur ce point ont été finalisés.",
    "comments.comment.anonymous": "anoniem",
    "comments.comment.button": "Ecrire un nouveau commentaire",
    "comments.comment.buttonReply": "réplique",
    "comments.comment.cancel": "brisé",
    "comments.comment.content": "Uw commented",
    "comments.comment.hasCommented": "géanotechiste",
    "comments.comment.login": "Connectez-vous pour poster un commentaire",
    "comments.comment.past": "levain",
    "comments.comment.replyFrom": "Réponse à la",
    "comments.comment.showReplies": "Afficher les réponses",
    "comments.comment.showReply": "Mot de passe Toon",
    "comments.comment.submit": "verzending",
    "comments.comment.title": "commentary titel",
    "comments.comment.userName": "Votre nom d'utilisateur",
    "comments.heading": "Suggestions, remarques, remarques, remarques, commentaires",
    "comments.hierarchy.flat": "parcelle",
    "comments.hierarchy.nested": "Genezen",
    "comments.hierarchy.title": "Voir les commentaires :",
    "comments.message.afterSaveError": "Votre commentaire ne peut pas être sauvegardé pour le moment !",
    "comments.message.afterSaveSuccess": "Votre commentaire a été enregistré.",
    "comments.message.afterSaveUnlock": "Votre commentaire a été enregistré, mais doit être revu par un administrateur.",
    "comments.noComments": "Aucune observation n'a encore été formulée.",
    "comments.reply": "AN :",
    "comments.show": "Afficher les commentaires",
    "comments.title": "reproches",
    "entries.edit": "Modifier l'entrée <strong>{{title}}</strong> Modifier l'entrée.",
    "entries.entries": "inzendingen",
    "entries.entry.fields.address.city": "stadje",
    "entries.entry.fields.address.country": "contrée",
    "entries.entry.fields.address.houseNumber": "numéro de maison",
    "entries.entry.fields.address.street": "straat",
    "entries.entry.fields.address.zip": "POSTCODE",
    "entries.entry.fields.prefixMissing": "Les champs suivants ne sont pas remplis :",
    "entries.entry.profile.edit": "Modifier le profil",
    "entries.entry.profile.publicProfile": "à ma page de profil public",
    "entries.entry.tooltip.alreadyCopied": "Vous rêvez déjà de cette entrée.",
    "entries.entry.tooltip.alreadyFollow": "Vous suivez déjà cette annonce de très près.",
    "entries.entry.tooltip.alreadyLiked": "Vous aimez déjà cette mention",
    "entries.entry.tooltip.copied": "Je suis content que tu veuilles aussi rêver de cette entrée. Votre photo de profil a été ajoutée à cet élément.",
    "entries.entry.tooltip.filterComments": "Cliquez ici pour afficher uniquement les commentaires dans l'Entryline",
    "entries.entry.tooltip.filterFollows": "Cliquez ici pour afficher uniquement Suivre dans la ligne d'enregistrement.",
    "entries.entry.tooltip.filterLikes": "Cliquez ici pour afficher uniquement Likes in the Entryline.",
    "entries.entry.tooltip.filterShares": "Cliquez ici pour voir seulement les actions de l'Entryline.",
    "entries.entry.tooltip.follow": "Cliquez ici pour suivre cette entrée",
    "entries.entry.tooltip.like": "Cliquez ici pour donner à cette entrée un Like.",
    "entries.entry.tooltip.noFollow": "Ne suivez que les travaux pour les entrées externes",
    "entries.entry.tooltip.noFollowNotLoggedIn": "Vous devez être connecté pour suivre ce message.",
    "entries.entry.tooltip.noLike": "Comme travailler uniquement avec des étrangers....",
    "entries.entry.ui.access": "Je ne vois que cette mention",
    "entries.entry.ui.added": "...a été ajouté.",
    "entries.entry.ui.addEntry": "Ajout d'entrées",
    "entries.entry.ui.addOpening": "Ajouter l'heure d'ouverture",
    "entries.entry.ui.address": "Mijn address",
    "entries.entry.ui.addStep": "Ajouter une étape",
    "entries.entry.ui.agreementHours": "Ouvert sur rendez-vous",
    "entries.entry.ui.allAccess": "Tout le monde peut voir cette entrée",
    "entries.entry.ui.assignedCategories": "catégories affectées",
    "entries.entry.ui.assignedCertificates": "Certificats Mijn",
    "entries.entry.ui.assignedProducts": "produits affectés",
    "entries.entry.ui.businessContact": "Coordonnées de l'entreprise",
    "entries.entry.ui.categories": "Catégories sous lesquelles je peux être trouvé",
    "entries.entry.ui.categoryFields": "Plus d'informations",
    "entries.entry.ui.commercialTitle": "Informations sur l'entreprise concernant l'enregistrement",
    "entries.entry.ui.companyTitle": "Coordonnées de mon entreprise",
    "entries.entry.ui.contactPerson": "Mes coordonnées",
    "entries.entry.ui.cssClass": "classe CSS pour le contenu",
    "entries.entry.ui.deliveryInfo": "Informations sur le service de livraison",
    "entries.entry.ui.descriptionInfo": "Conseil : Si vous sélectionnez des mots, vous pouvez attribuer des styles.",
    "entries.entry.ui.descriptionPlaceholder": "Description avec HTML. Double-cliquez sur le texte de la barre d'outils HTML (jusqu'à 600 caractères).",
    "entries.entry.ui.editEntry": "Modification d'une entrée",
    "entries.entry.ui.editEntryNew": "Créer une entrée",
    "entries.entry.ui.exclusionHours": "périodes d'exclusion",
    "entries.entry.ui.galleryImages": "Galerie de photos (2 ou 3 photos selon la configuration)",
    "entries.entry.ui.globalTitle": "nausée",
    "entries.entry.ui.hasKiosk": "J'ai un stand de vente...",
    "entries.entry.ui.imagesHorizontal": "Horizontaal",
    "entries.entry.ui.imagesInfo": "Les images peuvent être éditées sous \"Images/Vidéos/Documents\".",
    "entries.entry.ui.imagesOrientation": "alignement des images",
    "entries.entry.ui.imagesSquared": "équerre",
    "entries.entry.ui.imagesVertical": "Verticale",
    "entries.entry.ui.isListImage": "Cet écran est l'écran de synthèse.",
    "entries.entry.ui.isMainImage": "Cette image est l'image la plus importante",
    "entries.entry.ui.isMainImageAndListImage": "Cet écran apparaît comme l'écran principal de l'entrée.",
    "entries.entry.ui.kioskDescription": "Description de votre stand",
    "entries.entry.ui.listImage": "image de liste",
    "entries.entry.ui.logo": "emblem",
    "entries.entry.ui.longDescription": "Texte descriptif long",
    "entries.entry.ui.mainImage": "écran principal",
    "entries.entry.ui.makeListImage": "Définissez cet écran comme écran de synthèse.",
    "entries.entry.ui.makeListImageTooltip": "Les résultats de la recherche sont affichés sous forme de liste avec l'écran de synthèse. Si aucun écran de synthèse n'est sélectionné, l'écran principal est utilisé.",
    "entries.entry.ui.makeMainImage": "Définissez cet écran comme écran principal",
    "entries.entry.ui.makeMainImageTooltip": "L'écran principal s'affiche en haut du profil. Les images qui ne sont pas sélectionnées comme images principales ou de liste sont affichées dans la galerie de photos.",
    "entries.entry.ui.metaDescription": "description concise",
    "entries.entry.ui.metaDescriptionPlaceholder": "Brève description avec un maximum de 200 caractères",
    "entries.entry.ui.multiCode": "Code additionnel pour cette étape",
    "entries.entry.ui.multiDescription": "Description de cette étape",
    "entries.entry.ui.multiImages": "Photos pour cette étape",
    "entries.entry.ui.multiProgress": "paiement d'avance",
    "entries.entry.ui.multiTitle": "Titre pour cette étape",
    "entries.entry.ui.multiTitlePlaceholder": "Titre pour cette étape (jusqu'à 50 caractères)",
    "entries.entry.ui.noDescriptions": "La saisie n'a pas encore de texte descriptif.",
    "entries.entry.ui.noEntries": "Aucune entrée trouvée.",
    "entries.entry.ui.onlyVisibleForCustomers": "Cette entrée n'est visible que par les clients enregistrés.",
    "entries.entry.ui.openAt": "s'ouvrir",
    "entries.entry.ui.openingHours": "heures d'ouverture",
    "entries.entry.ui.openingInfo": "Remarques sur les heures d'ouverture",
    "entries.entry.ui.openingReligious": "Adresse dans une communauté à prédominance catholique",
    "entries.entry.ui.overallProgress": "Cette entrée est réalisée à {{valeur}}%.",
    "entries.entry.ui.parking": "facilités de stationnement",
    "entries.entry.ui.parkingLots": "numéro",
    "entries.entry.ui.parkingType": "wijze",
    "entries.entry.ui.personImage": "Photo van de contactpersoon",
    "entries.entry.ui.relatedEvents": "Événements associés",
    "entries.entry.ui.rentKiosk": "Je souhaite louer un stand",
    "entries.entry.ui.resolveAddress": "Détermination des coordonnées",
    "entries.entry.ui.resolveAddressInfo": "Déterminez le Géopoint (position sur la carte) à partir de votre adresse. Sans un géopoint correct, ils peuvent ne pas être faciles à trouver.",
    "entries.entry.ui.resolveGeolocation": "Adresse Bepalen",
    "entries.entry.ui.routingPlaceholder": "Remplissez ici votre itinéraire en HTML.",
    "entries.entry.ui.selectEvent": "Sélectionner un événement",
    "entries.entry.ui.shortDescriptionInfo": "C'est en même temps la courte description",
    "entries.entry.ui.sharing": "Partager cette page sur {{sharing}}",
    "entries.entry.ui.shareMail": "Partager cette page par e-mail",
    "entries.entry.ui.skillsNeeded": "Pour ma soumission, je peux vraiment utiliser....",
    "entries.entry.ui.sortword": "Titre alternatif pour le triage",
    "entries.entry.ui.step": "arbre",
    "entries.entry.ui.stepInDream": "Dans quelle phase de l'enregistrement se trouve cette étape ?",
    "entries.entry.ui.stepInDreamAfter": "na realisatie",
    "entries.entry.ui.stepInDreamBefore": "pour la réalisation",
    "entries.entry.ui.stepInDreamDuring": "pendant la mise en œuvre",
    "entries.entry.ui.subtitle": "Sous-titre descriptif",
    "entries.entry.ui.subtitlePlaceholder": "Saisie des sous-titres (jusqu'à 60 caractères)",
    "entries.entry.ui.tabCategories": "catégoriesën",
    "entries.entry.ui.tabCategoriesMobile": "Kat.",
    "entries.entry.ui.tabCompany": "informatique d'entreprise",
    "entries.entry.ui.tabDescription": "Description de la",
    "entries.entry.ui.tabDescriptionMobile": "téléavertisseur",
    "entries.entry.ui.tabExhibitor": "cornets d'exposition",
    "entries.entry.ui.tabImages": "Photos/Vidéos/Documents",
    "entries.entry.ui.tabImagesMobile": "Photos/Vidéos/Fichiers",
    "entries.entry.ui.tabRelations": "cautionné",
    "entries.entry.ui.tags": "étiquettes",
    "entries.entry.ui.title": "L'entrée apparaît sous les noms suivants",
    "entries.entry.ui.titleError": "L'entrée doit être mentionnée avant la sauvegarde des données.",
    "entries.entry.ui.titlePlaceholder": "Entrer le titre (jusqu'à 50 caractères)",
    "entries.entry.ui.visible": "Cet élément est visible",
    "entries.entry.ui.promotion": "Participation à des actions pour cette entrée",
    "entries.entry.ui.regions": "Catégories de régions pour cette entrée",
    "entries.job.ui.promotion": "Participer à des actions pour cet emploi",
    "entries.event.edit": "Modifier un événement <strong>{{title}}</strong>.",
    "entries.event.ui.addEvent": "Ajouter un événement",
    "entries.event.ui.cooperation": "Partenaire Kooperationspartner",
    "entries.event.ui.directions": "Comment nous trouver",
    "entries.event.ui.editEvent": "Appliquer l'événement",
    "entries.event.ui.editEventNew": "Créer un événement d'entreprise",
    "entries.event.ui.endDate": "Fin de l'événement (jour)",
    "entries.event.ui.eventDone": "rapport du salon",
    "entries.event.ui.noEvents": "Aucun événement trouvé",
    "entries.event.ui.openEvent": "Bel evenement",
    "entries.event.ui.opening": "heures d'ouverture",
    "entries.event.ui.startDate": "Début de l'événement (jour)",
    "entries.event.ui.tabCategories": "catégoriesën",
    "entries.event.ui.tabDescription": "Description de la",
    "entries.event.ui.tabOpening": "Date et heures",
    "entries.event.ui.tabRelations": "cautionné",
    "entries.event.ui.title": "L'événement apparaît sous les noms suivants",
    "entries.event.ui.upcoming": "bientôt",
    "entries.event.ui.moreEvents": "Plus les événements",
    "entries.open": "Appeler une entrée dans le Frontend",
    "entries.person.ui.company": "standvastig",
    "entries.person.ui.female": "vrouw",
    "entries.person.ui.fullName": "Prénom et nom de famille",
    "entries.person.ui.fullSize": "Entièrement formaté",
    "entries.person.ui.gender": "Sexe",
    "entries.person.ui.greeting": "palefrenier",
    "entries.person.ui.male": "baaaa",
    "entries.person.ui.open": "Directement à l'entrée de cette personne",
    "entries.person.ui.personAddress": "Adres van de persoon",
    "entries.person.ui.personContact": "Coordonnées personnelles",
    "entries.person.ui.personImage": "persoonsimago",
    "entries.person.ui.position": "Positie/Type",
    "entries.person.ui.quote": "Citation/illustration sous-titres",
    "entries.person.ui.signature": "typiquement beeld",
    "entries.poi.ui.title": "L'emplacement apparaît sous les noms suivants",
    "entries.product.edit": "Modifier le produit <strong>{{title}}</strong>.",
    "entries.product.fields.engine": "Moteur",
    "entries.product.fields.horsePower": "Puissance",
    "entries.product.menu.allAttachments": "Aperçu des pièces jointes",
    "entries.product.menu.allCarriers": "Aperçu des transporteurs",
    "entries.product.menu.attachments": "Outils portés",
    "entries.product.menu.carriers": "Motoculteurs",
    "entries.product.tags.eco": "Eco",
    "entries.product.tags.neu": "Nouveau",
    "product.ui.content": "Contenu",
    "product.ui.ingredients": "Composants",
    "product.ui.ingredientsFood": "Ingrédients",
    "product.ui.nonPhysical": "Produit électronique à télécharger",
    "entries.product.ui.addProduct": "Ajouter un produit",
    "entries.product.ui.equipmentVariants": "Variantes d'équipement",
    "entries.product.ui.variants": "Variantes",
    "entries.product.ui.gauge": "Elevage en cm",
    "entries.product.ui.gaugeMin": "Largeur minimale de voie en cm",
    "entries.product.ui.inclusive": "Inclus",
    "entries.product.ui.live": "Ervaring en direct",
    "entries.product.ui.netPrize": "Prix net plus TVA. Sans équipement et accessoires spéciaux. Ex usine Entschenreuth, plus les frais d'emballage, de transport, d'assurance transport, de montage et de mise en service.",
    "entries.product.ui.noProducts": "Aucun produit trouvé",
    "entries.product.ui.noPurchaseOnThisPage": "S'il n'est pas possible de passer une commande sur cette page, veuillez appeler le produit.",
    "entries.product.ui.onlyBasicVariant": "Uniquement en version de base",
    "entries.product.ui.onlyWithCarrier": "Uniquement disponible à l'achat d'une unité de base",
    "entries.product.ui.order": "Ajouter au panier",
    "entries.product.ui.overview": "Aperçu de la",
    "entries.product.ui.prizeFrom": "de ",
    "entries.product.ui.noPrize": "Pour le prix, veuillez vous adresser à votre interlocuteur.",
    "entries.product.ui.required": "Seulement avec ",
    "entries.product.ui.suitableFor": "Histoire pour",
    "entries.product.ui.suitableForAttachments": "Convient pour les accessoires suivants",
    "entries.product.ui.suitableForCarriers": "Convient pour les véhicules suivants",
    "entries.product.ui.title": "Produit",
    "entries.product.ui.variant": "Variante/Bundle",
    "entries.product.ui.variantNotOrderable": "La variante sélectionnée ne peut pas être commandée",
    "entries.product.ui.bundleparts": "comprenant",
    "entries.product.ui.chooseVariant": "Choisissez une variante",
    "entries.product.ui.chooseVariantMobile": "Choisir la variante",
    "entries.product.ui.combinationNotAvailable": "Combinaison pas disponible",
    "entries.progress.title": "Comment améliorer votre inscription",
    "entries.tabbar.basics": "basisgevens",
    "entries.tabbar.entry": "données saisies",
    "entries.tabbar.gallery": "images",
    "entries.tabbar.layout": "configuration",
    "entries.tabbar.openingHours": "heures d'ouverture",
    "entries.tabbar.order": "format de commande",
    "entries.tabbar.tooltip.entryNotSavedDetail": "Les données d'entrée ne peuvent être éditées avec le titre qu'après l'enregistrement de l'entrée.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "Les données de mise en page ne peuvent être modifiées qu'après la sauvegarde de l'entrée avec titre.",
    "entries.types.poi": "poi",
    "entries.types.standard": "standard",
    "entries.ui.groupDetailName": "Nom pour les entrées de groupe",
    "entries.ui.groupName": "Nom des groupes (région)",
    "entryTypes.entry": "incitant",
    "entryTypes.event": "Événement",
    "entryTypes.poi": "plaats",
    "errors.blank": "doit être rempli...",
    "errors.description": "Dit veld",
    "errors.email": "doit être une adresse e-mail valide",
    "errors.empty": "ne peut pas être vide...",
    "errors.exclusion": "est réservé...",
    "errors.inclusion": "n'est pas inclus dans la liste...",
    "errors.invalid": "est invalide...",
    "errors.slug": "La limace ne doit contenir que des lettres minuscules, des chiffres et des \"-\".",
    "forms.ui.deleteFile": "Supprimer fichier",
    "forms.ui.isSubmitted": "Votre demande a été soumise. Nous vous contacterons dès que possible.",
    "forms.ui.uploadSuccess": "{{file}} téléchargé avec succès",
    "media.documents.search.noResult": "Veuillez entrer un terme de recherche ou sélectionner une catégorie.",
    "media.documents.search.placeholder": "Recherche par numéro de série ou type",
    "media.documents.search.reset": "Réinitialiser le résultat de la recherche",
    "media.ui.copyright": "Authorsrecht",
    "media.ui.cropFormat": "disposition standard",
    "media.ui.cropImage": "knipsel",
    "media.ui.cropImageDuplicate": "Duplication et rognage",
    "media.ui.description": "Description de la",
    "media.ui.filename": "stock serpent",
    "media.ui.imageSaved": "Image enregistrée",
    "media.ui.processingImage": "L'image sera coupée, veuillez patienter.",
    "media.ui.resetCrop": "Réinitialiser blanco",
    "media.ui.resetImage": "Retour à l'original",
    "media.ui.rotateImage": "Tourner le chiffre de 90° dans le sens des aiguilles d'une montre",
    "media.ui.title": "benaming",
    "openingHours.additionalInfo": "Informations sur les heures d'ouverture",
    "openingHours.addOpening": "Ajouter l'heure d'ouverture",
    "openingHours.date.day.di": "Di",
    "openingHours.date.day.do": "Do",
    "openingHours.date.day.fr": "Fr",
    "openingHours.date.day.mi": "Mi",
    "openingHours.date.day.mo": "Mo",
    "openingHours.date.day.sa": "Sa",
    "openingHours.date.day.so": "So",
    "openingHours.dateS": "afspraak",
    "openingHours.delete": "Enlever",
    "openingHours.from": "portière",
    "openingHours.options": "aanbod",
    "openingHours.time": "keer",
    "openingHours.to": "d'ici à ce que",
    "openingHours.type": "Type d'heures d'ouverture",
    "opt-in.error-can-not-update-member": "Les modifications des détails du membre pour {{email}} n'ont pas pu être effectuées.",
    "opt-in.error-list-does-not-exist": "Aucune liste n'a été trouvée pour les données spécifiées.",
    "opt-in.error-list-does-not-need-validation": "La liste spécifiée n'a pas d'opt-in.",
    "opt-in.error-member-not-in-list": "Aucun membre d'une liste n'a été trouvé pour les données fournies.",
    "opt-in.success-member-verified": "Le {{email}} a été ajouté à la liste {{list name}}}.",
    "opt-out.error-can-not-update-member": "Les modifications des détails du membre pour {{email}} n'ont pas pu être effectuées.",
    "opt-out.error-list-does-not-exist": "Aucune liste n'a été trouvée pour les données spécifiées.",
    "opt-out.error-list-does-not-have-opt-out": "La liste spécifiée n'a pas d'opt-out.",
    "opt-out.error-member-not-in-list": "Aucun membre d'une liste n'a été trouvé pour les données fournies.",
    "opt-out.success-membership-terminated": "Le {{email}} a été supprimé de la {{list name}}.",
    "password.errors.not-secure-enough": "Nous recommandons un mot de passe d'au moins 8 caractères, lettres majuscules et minuscules, chiffres et caractères spéciaux ($@$!%*# ? &amp;).",
    "password.errors.password-and-conf-do-not-match": "Les deux mots de passe doivent être identiques.",
    "password.quality.0": "Zeer slecht",
    "password.quality.1": "Slecht",
    "password.quality.2": "Voldoende",
    "password.quality.3": "fidjn",
    "password.quality.4": "Zeer est parti",
    "profile.entries.copycats": "Les soumissions {{userName}} veulent aussi avoir un",
    "profile.entries.donates": "Entrée supportée par {{userName}}.",
    "profile.entries.entries": "Les soumissions de {{userName}}.",
    "profile.entries.follows": "Soumissions suivies de {{userName}}.",
    "profile.entries.joins": "Entrées auxquelles {{userName}} participe",
    "profile.marketplace.fields.energyRequired": "besoins en électricité",
    "profile.marketplace.fields.livingAnimals": "Transport d'animaux vivants",
    "profile.marketplace.fields.loadingUnloading": "Obligatoire à l'arrivée et au départ",
    "profile.marketplace.fields.maxRange": "Pour mon offre, j'ai déjà parcouru tant de kilomètres.",
    "profile.marketplace.fields.placeRequired": "Exigences en matière d'espace de stationnement",
    "profile.marketplace.fields.securityRequired": "Mettre en place un système de sécurité",
    "profile.marketplace.fields.shows": "gamme de programmes",
    "profile.marketplace.fields.spaceRequired": "Encombrement en m² nécessaire",
    "profile.marketplace.fields.waterRequired": "Besoins en eau en m³ (m³)",
    "profile.marketplace.new": "Nouvelle offre ou nouvelle demande",
    "profile.marketplace.offer.delete": "Enlever",
    "profile.marketplace.offer.description": "Description de la",
    "profile.marketplace.offer.descriptionPlaceholder": "Une description significative, pour qu'un autre utilisateur puisse en faire quelque chose.",
    "profile.marketplace.offer.new": "Faire une nouvelle offre",
    "profile.marketplace.offer.relatedEntry": "Poste affecté",
    "profile.marketplace.offer.tags": "Etiquettes pour l'offre/demande (se trouvera mieux avec elle)",
    "profile.marketplace.offer.title": "Ik bied",
    "profile.marketplace.request.delete": "Supprimer la demande",
    "profile.marketplace.request.new": "Faire une nouvelle demande",
    "profile.marketplace.request.title": "J'en ai besoin.",
    "profile.messages.approve": "Bevestigen",
    "profile.messages.as": "terwijl",
    "profile.messages.commentPre": "levain",
    "profile.messages.commentSuff": "géanotechiste",
    "profile.messages.decided": "a décidé de le faire",
    "profile.messages.deletedUser": "Utilisateur supprimé",
    "profile.messages.donateDream": "Merci de soutenir ce participant en paroles et en actes pour réaliser sa soumission. Il recevra votre message immédiatement et, espérons-le, vous contactera et acceptera votre aide.",
    "profile.messages.dreamer": "quelques particularités",
    "profile.messages.dreamToo": "de rêver aussi",
    "profile.messages.enter": "joint",
    "profile.messages.favoriteDream": "Vous suivez cette entrée maintenant.",
    "profile.messages.follow": "suivi",
    "profile.messages.has": "is",
    "profile.messages.is": "is",
    "profile.messages.join": "rétrécissement",
    "profile.messages.joinDream": "Vous voulez rêver cette entrée.",
    "profile.messages.likeDream": "Vous aimez cette entrée maintenant.",
    "profile.messages.sendText": "Votre message a bien été envoyé.",
    "profile.messages.shareDream": "Vous avez réussi à partager ce message.",
    "profile.messages.supporter": "arbitres monétaires",
    "profile.messages.want": "volonté",
    "profile.messages.youAre": "U coudée",
    "profile.messages.youWant": "U flétrissement",
    "profile.newsletter.notSubscribed": "ondertekenenen",
    "profile.newsletter.privacyInformation": "En cliquant sur \"s'abonner\", vous acceptez que nous vous envoyions les futures lettres d'information de cet abonnement (opt-in). Vous pouvez vous désinscrire de la newsletter à tout moment en cliquant à nouveau sur \"subscribe\" (opt-out).",
    "profile.newsletter.subscribed": "souscrit",
    "profile.press.articleDetail": "détail des articles",
    "profile.press.downloadImage": "Télécharger l'image",
    "profile.press.downloadImages": "Télécharger des images",
    "profile.press.downloadText": "Tekst exporters",
    "profile.press.filterAuthor": "Filtrer sur auteur",
    "profile.press.hide": "être masculin",
    "profile.press.show": "vervagène",
    "profile.progress.title": "Comment améliorer votre profil",
    "register.ui.addressData": "adresgevens",
    "register.ui.addressInfo": "Vous n'avez pas besoin d'indiquer votre adresse personnelle. Cependant, cela conduit à des calculs d'itinéraire et de distance corrects.",
    "register.ui.companyData": "les coordonnées de votre entreprise",
    "register.ui.companyDataMandatory": "Vous devez saisir le nom de votre société.",
    "register.ui.emptyFirstName": "Vous devez entrer votre prénom",
    "register.ui.emptyLastName": "Vous devez entrer votre nom de famille.",
    "register.ui.emptyMail": "Vous devez entrer une adresse e-mail valide.",
    "register.ui.emptyUserName": "Vous devez entrer un nom d'utilisateur",
    "register.ui.language": "taal",
    "register.ui.loginData": "référentiels",
    "register.ui.password": "Mot de passe",
    "register.ui.passwordRepeat": "Répéter le mot de passe",
    "register.ui.personalData": "Vos données personnelles",
    "register.ui.takenEmail": "Cet e-mail a déjà été saisi par un utilisateur.",
    "register.ui.takenUserName": "Ce nom d'utilisateur a déjà été attribué",
    "register.ui.timezone": "tijdzone",
    "register.ui.typeMinimum": "Vous devez sélectionner au moins un type",
    "register.ui.usernameInfo": "Important : C'est votre nom d'utilisateur pour vous connecter.",
    "search.allDistances": "Tous les autres produits",
    "search.andFilter": "fr Réglages du filtre",
    "search.closeTags": "Balises Verberg",
    "search.for": "à la suite de",
    "search.zipCountries": "Codes postaux de",
    "search.hits": "scorie",
    "search.openTags": "Tags tonen",
    "search.removeDatefilter": "Enlever le filtre de date",
    "search.resulted": "a donné lieu à",
    "search.resultType.category": "Catégorie",
    "search.resultType.entry": "Distributeur",
    "search.resultType.event": "Evénement",
    "search.resultType.group": "Groupe",
    "search.resultType.page": "Page",
    "search.resultType.person": "Personne",
    "search.resultType.post": "Poste",
    "search.resultType.product": "Produit",
    "search.search": "Rechercher",
    "search.searchBarPlaceholder": "consultable",
    "search.searchBarPlaceholderEntries": "Recherche de revendeurs. ",
    "search.searchBarPlaceholderEntriesPosition": "Préciser la position pour la recherche de rayon.",
    "search.searchBarPlaceholderGeoSearch": "Recherche par adresse...",
    "search.searchBarPlaceholderMobile": "consultable",
    "search.searchBarPlaceholderProducts": "Recherche de produits",
    "search.searchBarPositionTooltip": "Déterminer la position ou entrer le lieu avec l'adresse",
    "search.showAll": "Toon alle",
    "social.comment": "commentary",
    "social.Comment": "commentary",
    "social.CopycatJoiner": "Rêvez votre entrée",
    "social.dream.donateAmount": "Soutien avec une somme d'argent",
    "social.dream.donateDreampoints": "Support avec Dreampoints",
    "social.dream.donateInfo": "Le message est privé et ne peut être lu que par le propriétaire de l'entrée.",
    "social.dream.donateTip": "Accompagnement par le conseil et l'action",
    "social.dream.done": "Prête",
    "social.dream.edit": "Modification d'une entrée",
    "social.dream.findDreams": "Trouvez de petites et grandes entrées ici et faites en sorte que votre entrée soit vraie et/ou aidez les autres à réaliser leurs entrées.",
    "social.dream.followedDreams": "Incendibilités que vous suivez",
    "social.dream.giveEmail": "Mijn e-mail adres delen",
    "social.dream.givePhone": "Entrez mon numéro de téléphone",
    "social.dream.joinerApproved": "Vous êtes impliqué dans cette soumission.",
    "social.dream.joinerButton": "Rejoindre cette entrée",
    "social.dream.joinerButtonMobile": "Deelnemen",
    "social.dream.joinerDream": "rêve collectif",
    "social.dream.joinerInfo": "Si le propriétaire de l'inscription confirme votre participation, cette inscription devient un rêve de groupe, dans lequel vous avez plus de droits.",
    "social.dream.joinerMessage": "Entrez ici votre message pour le propriétaire de l'entrée :",
    "social.dream.joinerPending": "Le propriétaire de l'inscription doit quand même confirmer votre participation.",
    "social.dream.joinerSupport": "Vous soutenez ce message",
    "social.dream.joinerSupportButton": "Rapport Steun dit",
    "social.dream.joinerSupportButtonMobile": "ondersteuning",
    "social.dream.joinerSupportInfo": "Nous travaillons constamment à des améliorations, par exemple avec de l'argent ou des points d'entrée pour le soutien. Restez à l'écoute.",
    "social.dream.joinerSupportPending": "Le propriétaire de l'entrée doit toujours autoriser votre soutien.",
    "social.dream.member": "paupière",
    "social.dream.members": "plomb",
    "social.dream.meToo": "Je veux le faire aussi.",
    "social.dream.meTooInfo": "Montrez au propriétaire de l'entrée que vous pensez que l'entrée est grande et que vous voulez faire cette entrée aussi bien.",
    "social.dream.noFollow": "Vous ne suivez pas une entrée !",
    "social.dream.noJoiner": "Vous n'êtes pas encore membre d'un groupe de rêve.",
    "social.dream.noLikes": "Vous n'aimez pas encore la mention !",
    "social.dream.noSupport": "Vous ne supportez pas l'accès à une annonce",
    "social.dream.registerFirstText": "Vous devez être connecté pour pouvoir supprimer une entrée, la soutenir ou indiquer que vous souhaitez effectuer une entrée. L'inscription au partage est rapide et gratuite. Cliquez simplement sur le bouton \"S'inscrire\".",
    "social.dream.registerFirstTitle": "Vous rêvez aussi de soutien ?",
    "social.dream.settings": "paramètres de saisie",
    "social.dream.shareTitle": "Deel de droom",
    "social.dream.similar": "Entrées connexes",
    "social.dreamline.addImageToDream": "Ajouter cette image à votre soumission",
    "social.dreamline.addMedia": "Ajouter une image/vidéo",
    "social.dreamline.allActivities": "Toutes les activités Dreamline",
    "social.dreamline.anonymous": "anoniem",
    "social.dreamline.commentButton": "commentary",
    "social.dreamline.comments": "...écrit :",
    "social.dreamline.depublished": "Ce commentaire a été retiré en raison d'un comportement inapproprié.",
    "social.dreamline.dreampointsInfo": "Ce sont les Dreampoints de l'entrée. Plus d'informations sur Dreampoints peuvent être trouvées dans votre profil.",
    "social.dreamline.fewDreampoints": "n'a que quelques Dreampoints. Commentez, aimez ou partagez la soumission.",
    "social.dreamline.filterBySteps": "Après Tredenfiltern",
    "social.dreamline.filterComment": "reproches",
    "social.dreamline.filteredBy": "Activités filtrées par",
    "social.dreamline.filteredBySteps": "Filtré par étapes",
    "social.dreamline.filterFavorite": "Volgt",
    "social.dreamline.filterLike": "Aime",
    "social.dreamline.filterShare": "aandelas",
    "social.dreamline.filterStep": "étappes",
    "social.dreamline.flagged": "Ce message a été signalé",
    "social.dreamline.isComment": "géanotechiste",
    "social.dreamline.isCopycatJoiner": "ook dromen",
    "social.dreamline.isFirstUpdate": "artisanal",
    "social.dreamline.isShare": "à part",
    "social.dreamline.isStep": "a fait un nouveau pas",
    "social.dreamline.isUpdate": "bijgewerkt",
    "social.dreamline.lastEntry": "Mise à jour Laatste",
    "social.dreamline.locked": "Ce contenu n'est visible que pour les autres rêveurs.",
    "social.dreamline.lockedCTA": "Rejoindre cette entrée maintenant",
    "social.dreamline.noMessages": "Cette entrée n'a pas encore d'activité. Écrivez votre premier commentaire maintenant.",
    "social.dreamline.noMessagesFiltered": "Aucune activité avec ces paramètres de filtre pour cet élément.",
    "social.dreamline.nowFollower": "Suivez cette entrée !",
    "social.dreamline.nowJoiner": "est maintenant un partisan...",
    "social.dreamline.nowLikes": "a aimé cette entrée maintenant !",
    "social.dreamline.nowSharing": "a partagé cette entrée !",
    "social.dreamline.onlyJoinerVisible": "Uniquement visible pour les autres rêveurs / supporters",
    "social.dreamline.prefixComment": "a déjà effectué cette saisie auparavant",
    "social.dreamline.prefixCopycatJoiner": "Les demandes pour recevoir cette mention en tant que",
    "social.dreamline.prefixFavorite": "suit cette entrée, puisque",
    "social.dreamline.prefixJoiner": "appuie cette affirmation puisque",
    "social.dreamline.prefixLike": "aime cette entrée parce que",
    "social.dreamline.prefixShare": "a déjà effectué cette saisie auparavant",
    "social.dreamline.prefixStep": "a prévu",
    "social.dreamline.prefixUpdate": "a déjà effectué cette saisie auparavant",
    "social.dreamline.profile": "Profil",
    "social.dreamline.provideImage": "Je mets cette photo à la disposition du propriétaire de l'entrée",
    "social.dreamline.resetFilter": "Réinitialiser le filtre",
    "social.dreamline.sendComment": "Verzenden Verzenden",
    "social.dreamline.sharePart": "uitwisseling",
    "social.dreamline.toggleStream": "reproches",
    "social.dreamline.toggleStreamSingle": "commentary",
    "social.dreamline.writeComment": "Ecrire un commentaire...",
    "social.Favorite": "Volg",
    "social.follow": "Volg",
    "social.Follow": "Volg",
    "social.hasLiked": "Vous avez déjà aimé cette entrée.",
    "social.join": "connectant",
    "social.Join": "connectant",
    "social.Joiner": "dromadaire",
    "social.like": "zoals",
    "social.Like": "zoals",
    "social.report.headlinePrefix": "U plié en",
    "social.report.headlineSuffix": "om te rapporteren.",
    "social.report.login": "Vous devez être connecté pour signaler le message.",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "Autres contenus offensants",
    "social.report.reportScam": "Fausses déclarations, intimidation.",
    "social.report.reportSex": "Contenu à caractère sexuel",
    "social.report.reportSpam": "C'est du spam",
    "social.report.reportViolence": "représentation de la violence",
    "social.report.send": "Envoyer un message",
    "social.report.title": "reporting",
    "social.share": "Aandeel",
    "social.Share": "Aandeel",
    "syd.ui.dreamerCommercial": "Norme B2B",
    "syd.ui.userCommercial": "B2B Dromer",
    "user.company.additionalWebsite": "Autre site web de l'entreprise (seulement domaine sans https://)",
    "user.company.companyName": "raison sociale",
    "user.company.copyContact": "Copiez les coordonnées des données de l'entreprise pour la saisie.",
    "user.company.email": "E-mail pour nous contacter",
    "user.company.facebook": "Compte Facebook de l'entreprise (nom de compte uniquement)",
    "user.company.fax": "Numéro de télécopieur pour un contact écrit",
    "user.company.google": "Compte Google+ de l'entreprise (nom de compte uniquement)",
    "user.company.instagram": "Compte Instagram de Bedrijf (alleen accountnaam)",
    "user.company.linkedin": "Compte LinkedIn de la société (nom du compte uniquement)",
    "user.company.newCustomerAccount": "Créer un compte d'entreprise",
    "user.company.noCustomerValidation": "Votre compte d'entreprise n'a pas encore été activé.",
    "user.company.noCustomerYet": "Votre inscription n'a pas encore été attribuée à un compte d'entreprise.",
    "user.company.phone": "Numéro de téléphone pour nous contacter",
    "user.company.pinterest": "Compte d'intérêt de la société (seulement le nom du compte)",
    "user.company.remarks": "annotations",
    "user.company.twitter": "Compte Twitter de l'entreprise (seulement nom de compte sans @)",
    "user.company.website": "Site web de l'entreprise (seul domaine sans https://)",
    "user.company.xing": "Compte Xing de la société (seulement le nom du compte)",
    "user.company.youtube": "Compte Youtube Bedrijf (alleen accountnaam)",
    "user.message.after-save-error": "Vos modifications n'ont pas pu être sauvegardées.",
    "user.message.after-save-success": "Vos modifications ont été sauvegardées.",
    "user.message.can-not-login": "Ces identifiants et mots de passe ne nous permettent pas de vous connecter ! Le nom d'utilisateur est-il correctement orthographié ? Mot de passe entré correctement ? Peut-être que quelque chose a mal tourné avec l'enregistrement.",
    "user.message.can-not-logout": "Nous ne pouvions pas vous déconnecter !",
    "user.message.conflict": "Ce nom d'utilisateur existe déjà, choisissez un autre !",
    "user.message.fileExistsError": "Le téléchargement a échoué. Le fichier existe déjà",
    "user.message.fileSizeError": "Le téléchargement a échoué. Le fichier est trop gros",
    "user.message.loginFailureBottom": "Vous n'avez peut-être pas encore de compte Entryer ?",
    "user.message.loginFailureTop": "Votre nom d'utilisateur est-il correctement orthographié ? Avez-vous correctement saisi votre mot de passe ?",
    "user.message.registration-error": "Une erreur s'est produite lors de l'enregistrement !",
    "user.message.registration-success": "Vous devriez bientôt recevoir un courriel de confirmation de notre part.",
    "user.message.unknownError": "Erreur inconnue lors du téléchargement du fichier",
    "user.message.upload-success": "Succès du téléchargement",
    "user.message.verify-error": "Votre adresse e-mail n'a pas pu être vérifiée. Contactez l'administrateur.",
    "user.message.verify-success": "Votre adresse e-mail a été confirmée. Vous pouvez maintenant vous inscrire :",
    "user.ui.accountGreeting": "Hallo,",
    "user.ui.accountMenuActivities": "Droompunten",
    "user.ui.accountMenuCompanySettings": "coordonnées de l'entreprise",
    "user.ui.accountMenuEntries": "Mijn inzendingen",
    "user.ui.accountMenuEntryPre": "encens",
    "user.ui.accountMenuEvents": "Mes événements",
    "user.ui.accountMenuFavorites": "Mijn favorieten",
    "user.ui.accountMenuFollows": "Inzendingen la volg Ik",
    "user.ui.accountMenuImages": "Photos/Vidéos/Documents",
    "user.ui.accountMenuJoin": "Mes rêves de groupe",
    "user.ui.accountMenuLikes": "Entrées que j'aime",
    "user.ui.accountMenuMessages": "nouvelles",
    "user.ui.accountMenuNewsletter": "S'abonner à ma newsletter",
    "user.ui.accountMenuPoi": "Mes lieux",
    "user.ui.accountMenuPress": "Mes articles",
    "user.ui.accountMenuProducts": "Mijn produiten",
    "user.ui.accountMenuProfile": "Profil",
    "user.ui.accountMenuRatings": "évaluations",
    "user.ui.accountMenuRegistrationData": "référentiels",
    "user.ui.accountMenuReviews": "herzieningen",
    "user.ui.accountMenuSettings": "milieu environnant",
    "user.ui.accountMenuSkillsOffer": "Ik bied...",
    "user.ui.accountMenuSkillsRequest": "Ik moet...",
    "user.ui.accountMenuSkillsSearch": "Demandes et offres",
    "user.ui.accountMenuSubscriptions": "Subscriptionen",
    "user.ui.accountMenuSubscritions": "Mijn subscription",
    "user.ui.accountMenuSupport": "Ondersteunde inzendingen inzendingen",
    "user.ui.accountMenuSupports": "Inzendingen the ik steun",
    "user.ui.accountNoMessages": "Vous n'avez pas encore reçu de messages.",
    "user.ui.accountProgressbar": "Votre profil est {{value}}% complet.",
    "user.ui.accountProgressbarEntry": "Votre entrée est complète à {{value}}%.",
    "user.ui.accountSettings": "Autres paramètres",
    "user.ui.accountCookieSettings": "Cliquez ici pour ajuster vos paramètres.",
    "user.ui.accountCookieEssential": "Essentiel",
    "user.ui.accountCookieAnalysis": "Analyse",
    "user.ui.accountCookieSocial": "Médias sociaux",
    "user.ui.accountCookieReviews": "Critiques",
    "user.ui.accountToolbarLogin": "Ouvrir une session",
    "user.ui.accountToolbarLoginTooltip": "Inscrivez-vous gratuitement et créez votre propre entrée",
    "user.ui.accountToolbarLogout": "Logout",
    "user.ui.adminPreview": "Aperçu",
    "user.ui.backToLogin": "Retour à la page de connexion",
    "user.ui.birthday": "Anniversaire",
    "user.ui.cannotLogin": "Vous ne pouvez pas vous connecter ? Cliquez ici pour réinitialiser votre mot de passe.",
    "user.ui.changePassword": "Je veux changer mon mot de passe",
    "user.ui.changeUserGroup": "Changer subscription",
    "user.ui.city": "ligature",
    "user.ui.companyName": "raison sociale",
    "user.ui.companyNameTooltip": "Important : Votre mention apparaîtra sous les noms de ces sociétés.",
    "user.ui.country": "contrée",
    "user.ui.createNewAccount": "Créer un nouveau compte utilisateur",
    "user.ui.dataDelete": "est utilisé pour d'autres importations. Voulez-vous supprimer définitivement le fichier ? Lorsque ce fichier est utilisé, il est remplacé par un caractère de remplissage.",
    "user.ui.dataUsage": "Le fichier est enregistré dans",
    "user.ui.dataUsageNone": "Le fichier n'est pas encore utilisé. Voulez-vous supprimer définitivement le fichier ?",
    "user.ui.deleteImage": "Supprimer l'image",
    "user.ui.description": "Remplissez ici une description générale de la personne ou de l'entreprise",
    "user.ui.documents": "Document",
    "user.ui.documentSearch": "Recherche de documents",
    "user.ui.download": "Télécharger {{title}}",
    "user.ui.email": "e-mailaddresses",
    "user.ui.fillOutForm": "Pour vous inscrire, tous les champs obligatoires doivent être complétés au complet.",
    "user.ui.firstName": "prénom",
    "user.ui.academicTitle": "Titre académique",
    "user.ui.gender": "H/F",
    "user.ui.genderFemale": "féminin",
    "user.ui.genderMale": "homme",
    "user.ui.genderThird": "indéterminé",
    "user.ui.housenumber": "Non.",
    "user.ui.iAmCustomer": "J'aimerais créer un compte Partagez votre rêve B2B.",
    "user.ui.internalInfo": "Nous avons des nouvelles pour vous. Veuillez cliquer ici.",
    "user.ui.lastLogin": "Se connecter à Laatste",
    "user.ui.lastName": "Achternaam",
    "user.ui.loginButton": "Ouvrir une session",
    "user.ui.loginTitle": "connexion de l'utilisateur",
    "user.ui.languageAvailable": "Disponible dans votre langue",
    "user.ui.languageMissing": "Disponible uniquement en",
    "user.ui.memberSince": "Les paupières sont",
    "user.ui.mobileNumber": "numéro de portable",
    "user.ui.myAddedStorage": "Photos mises à disposition",
    "user.ui.myStorage": "Annuaire Mijn",
    "user.ui.noRegisterMessage": "L'enregistrement automatique des utilisateurs n'est pas actif. Pour un compte d'utilisateur, veuillez contacter les adresses de contact spécifiées.",
    "user.ui.oldPassword": "Mot de passe précédent",
    "user.ui.password": "Mot de passe",
    "user.ui.pleaseSelect": "Veuillez sélectionner",
    "user.ui.registerButton": "Créer un compte maintenant",
    "user.ui.registerMessage": "Inscrivez-vous dès maintenant et mettez-vous directement au travail. C'est rapide et facile.",
    "user.ui.registerTitle": "Nouveau chez nous ?",
    "user.ui.requestPasswordReset": "Demander un nouveau mot de passe et supprimer l'ancien mot de passe",
    "user.ui.resetPassword": "Réinitialiser le mot de passe",
    "user.ui.resetPasswordPage": "Réinitialiser uw wachtwoord",
    "user.ui.resetPasswordSuccess": "Votre mot de passe a été réinitialisé, vous pouvez maintenant vous connecter.",
    "user.ui.selectCustomerType": "Ils sont... trié sur le volet",
    "user.ui.stayLoggedin": "Rester connecté",
    "user.ui.stayLoggedinInfo": "S'il s'agit d'un appareil accessible au public, désactivez cette option et déconnectez-vous à la fin.",
    "user.ui.street": "straat",
    "user.ui.uploadDrop": "Pour télécharger, glisser-déposer un fichier ici, ou",
    "user.ui.uploadSelectFile": "un meilleur et un meilleur choix",
    "user.ui.uploadToMyStorage": "Télécharger de nouvelles photos dans mon répertoire",
    "user.ui.uploadUserImage": "Sélectionner l'écran utilisateur",
    "user.ui.username": "Nom d'utilisateur (adresse e-mail)",
    "user.ui.userName": "inlognaam",
    "user.ui.visibilityInfo": "Astuce : Vous décidez de ce qui est affiché publiquement à partir de votre profil ! Cliquez sur l'icône de l'œil pour ajuster la visibilité de votre entrée :",
    "user.ui.zip": "code postal",
    "user.user": "quelques particularités",
    "user.users": "dragueurs",

    // auto translated
    "application.ui.and": "et",
    "application.ui.in": "sur",
    "application.ui.atHour": "à l'adresse suivante :",
    "application.ui.hour": "Voir",
    "application.ui.contact": "Contactez",
    "application.ui.email": "Courriel",
    "application.ui.goToMeasuring": "Points de mesure",
    "application.ui.imprintLink": "Responsable et légalement responsable du contenu de cette sous-page {{entry}} - Mentions légales",
    "application.ui.select": "Veuillez sélectionner",
    "application.ui.shop": "Boutique",
    "buttons.confirm": "Confirmer",
    "checkout.cart.title": "Mon panier d'achat",
    "checkout.cart.item": "Article",
    "checkout.cart.amount": "Numéro",
    "checkout.cart.edit": "Changer",
    "checkout.cart.price": "Prix",
    "checkout.cart.singlePrice": "Prix unitaire",
    "checkout.cart.product.isNonPhysical": "Le produit ne sera pas expédié (peut être disponible pour le téléchargement)",
    "checkout.cart.product.requiresExpressDelivery": "Produit frais. Doit être expédié EXPRESS.",
    "checkout.cart.product.deliveryProblem": "Le produit sélectionné peut être soumis à des retards de livraison.",
    "checkout.cart.subtotal": "Sous-total",
    "checkout.cart.deliveryCosts": "Frais d'expédition",
    "checkout.cart.total": "Total",
    "checkout.cart.help": "Vous avez des questions sur le processus de commande ? Nous avons rassemblé pour vous des informations importantes.",
    "checkout.cart.subtotalInfo": "TVA incluse",
    "checkout.cart.subtotalShipment": "plus les frais d'expédition divergents",
    "checkout.cart.checkout": "A la caisse",
    "checkout.cart.checkoutWithoutUser": "Commande sans compte client",
    "checkout.cart.checkoutFast": "Vérification rapide",
    "checkout.cart.deliveryInfo": "Date de livraison prévue pour les articles dans le panier",
    "checkout.cart.orderSuccess": "Votre commande a été acceptée.",
    "checkout.cart.myOrder": "Ma commande",
    "checkout.checkout.orderNumber": "Numéro de commande",
    "checkout.checkout.account": "Compte client",
    "checkout.checkout.andOrder": "et ordre",
    "checkout.checkout.delivery": "Adresse de livraison et d'expédition",
    "checkout.checkout.delivery.type": "L'envoi a lieu avec",
    "checkout.checkout.delivery.street": "Adresse ligne 1",
    "checkout.checkout.delivery.houseNumber": "Non.",
    "checkout.checkout.delivery.co": "Suffixe de l'adresse (c/o)",
    "checkout.checkout.delivery.city": "Ville",
    "checkout.checkout.delivery.selectCountry": "Veuillez choisir un pays",
    "checkout.checkout.delivery.addressBoth": "Facture et adresse de livraison",
    "checkout.checkout.delivery.addressInvoice": "Adresse de facturation",
    "checkout.checkout.delivery.addressDelivery": "Adresse de livraison",
    "checkout.checkout.delivery.default": "Définissez cette adresse comme mon adresse de livraison par défaut",
    "checkout.checkout.delivery.otherAddress": "L'adresse de livraison diffère de l'adresse de facturation",
    "checkout.checkout.delivery.dhlNr": "Votre numéro de poste DHL",
    "checkout.checkout.delivery.packingStation": "Numéro de la station d'emballage",
    "checkout.checkout.delivery.showPackingStations": "Afficher les stations de conditionnement près de mon adresse",
    "checkout.checkout.delivery.wantExpressShipping": "Livraison express",
    "checkout.checkout.delivery.isPickUp": "Collecte sur site",
    "checkout.checkout.delivery.specialCountryInfo": "Votre pays ne peut pas être sélectionné ?",
    "checkout.checkout.delivery.specialCountry": "Votre pays en dehors de notre mode de transport standard",
    "checkout.checkout.delivery.specialCountryMessage": "Les produits individuels de votre panier ne peuvent pas être expédiés dans tous les pays. Veuillez retirer certains produits de votre commande jusqu'à ce que votre pays puisse être sélectionné ou contactez-nous.",
    "checkout.checkout.payment": "Mode de paiement",
    "checkout.checkout.paymentTitle": "Comment comptez-vous payer ?",
    "checkout.checkout.paymentSelected": "Mode de paiement choisi",
    "checkout.checkout.payment.cc": "Carte de crédit",
    "checkout.checkout.payment.ccInfo": "Pour les paiements par carte au sein de l'UE, votre identité doit être confirmée chaque fois que vous payez en ligne. Après la commande, vous serez guidé par la confirmation - fournie par votre banque.",
    "checkout.checkout.payment.complimentary": "gratuit",
    "checkout.checkout.payment.paypal": "PayPal",
    "checkout.checkout.payment.paypalInfo": "Vous serez redirigé vers PayPal pour compléter le processus de paiement. Votre compte PayPal ne sera pas débité avant que vous ne confirmiez la commande à l'étape suivante.",
    "checkout.checkout.payment.sepa": "Débit direct (SEPA)",
    "checkout.checkout.payment.sepaLegal": "J'autorise {{siteOwner}} {{siteOwnerAddress}} (Identification du créancier : {{siteOwnerCreditorID}}) pour encaisser des paiements sur mon compte par prélèvement automatique. Dans le même temps, je donne instruction à mon établissement de crédit d'honorer les prélèvements {{siteOwner}} à mon compte.",
    "checkout.checkout.payment.noSepa": "Votre adresse de facturation ou de livraison se trouve en dehors de l'UE. C'est pourquoi nous ne pouvons pas vous proposer de paiement par prélèvement automatique.",
    "checkout.checkout.payment.sepaInfo": "Rapide et simple. Lors de l'exécution de votre commande (étape suivante), il vous sera demandé de saisir vos coordonnées bancaires (IBAN). Préparez-les, s'il vous plaît. Lorsque le transfert est terminé, vous recevrez une confirmation.",
    "checkout.checkout.payment.prepayment": "Paiement anticipé (virement bancaire)",
    "checkout.checkout.payment.prepaymentInfo": "Après avoir passé la commande, nous vous enverrons les informations relatives au virement bancaire par courrier électronique. Nous ne pouvons réserver les articles de votre commande que pour une durée maximale de 7 jours. Veuillez conserver cette fenêtre de temps pour le virement bancaire. Dès que nous recevons le paiement, la commande est expédiée.",
    "checkout.checkout.payment.invoice": "Facture",
    "checkout.checkout.payment.invoiceInfo": "Le montant de la facture est dû à la date de paiement indiquée dans le courriel de confirmation d'expédition.",
    "checkout.checkout.payment.noInvoice": "Malheureusement, nous ne pouvons pas vous offrir le paiement par facture pour cette commande. Veuillez choisir l'un des autres modes de paiement.",
    "checkout.checkout.payment.sofort": "Sofortüberweisung",
    "checkout.checkout.payment.sofortInfo": "Lors de l'exécution de votre commande (étape suivante), il vous sera demandé de saisir vos coordonnées bancaires (IBAN). Préparez-les, s'il vous plaît. Lorsque le transfert est terminé, vous recevrez une confirmation.",
    "checkout.checkout.payment.accountName": "Titulaire du compte",
    "checkout.checkout.payment.accountEmail": "Adresse électronique de confirmation",
    "checkout.checkout.sepaAndOrder": "Mandat de prélèvement pour {{totalAmount}} € confirmer et ordonner avec dépens",
    "checkout.checkout.sofortAndOrder": "Mandat de transfert pour {{totalAmount}} € confirmer et ordonner avec dépens",
    "checkout.checkout.title": "Consultez le site",
    "checkout.checkout.next": "en savoir plus",
    "checkout.checkout.order": "Acheter maintenant",
    "checkout.checkout.orderConsentPre": "Avec l'ordre vous vous expliquez avec notre",
    "checkout.checkout.orderConsentPost": "a accepté.",
    "checkout.checkout.noAccount": "Ne pas créer de compte client",
    "checkout.redirect.countryPriceChange": "Les prix sont différents dans votre pays, veuillez prendre note du changement de prix.",
    "entries.entry.fields.language": "Langue",
    "entries.entry.ui.relatedJobs": "Emplois connexes",
    "entries.entry.ui.relatedArticles": "Articles connexes",
    "entries.entry.ui.relatedCampaigns": "Campagnes connexes",
    "entries.entry.ui.selectJob": "Choisir un emploi",
    "entries.entry.ui.selectArticle": "Sélectionnez un article",
    "entries.entry.ui.selectCampaign": "Choisir une campagne",
    "entries.person.ui.diverse": "Sans",
    "entries.poi.ui.poiEvents": "Événements à cet endroit",
    "entries.product.ui.choose": "sélectionnez",
    "entries.product.ui.discount": "Remise",
    "entries.product.ui.discountSelect": "% d'avantage de réduction",
    "entries.product.ui.netPrizeDefault": "y compris la taxe sur la valeur ajoutée, plus",
    "entries.product.ui.deliveryTerms": "Frais d'expédition",
    "entries.product.ui.notOrderable": "Veuillez choisir une variante.",
    "entries.product.ui.prizeDecimalPoint": ",",
    "entries.product.ui.item": "Pièce",
    "entries.product.ui.prizePerItem": "Prix par pièce",
    "entries.product.ui.size": "Taille",
    "errors.mandatory": "{{field}} doit être rempli",
    "errors.mandatoryCheckbox": "Doit être confirmé.",
    "errors.mandatorySubmit": "Vous ne pouvez soumettre le formulaire que lorsque tous les champs obligatoires ont été remplis.",
    "forms.ui.formErr": "Erreur d'envoi du formulaire",
    "forms.ui.formSent": "Formulaire envoyé",
    "forms.ui.invalidMail": "Veuillez entrer une adresse électronique valide.",
    "forms.ui.missingFields": "Les champs suivants doivent être remplis :",
    "forms.ui.fieldBuilder.street": "Rue",
    "forms.ui.fieldBuilder.houseNumber": "Non.",
    "forms.ui.fieldBuilder.zip": "POSTCODE",
    "forms.ui.fieldBuilder.city": "Ville",
    "forms.ui.fieldBuilder.country": "Pays",
    "forms.ui.fieldBuilder.firstName": "Prénom",
    "forms.ui.fieldBuilder.lastName": "Nom de famille",
    "forms.ui.privacyRead": "J'ai pris note de la politique de confidentialité",
    "jobs.ui.employmentType.FULL_TIME": "Temps plein",
    "jobs.ui.employmentType.PART_TIME": "Temps partiel",
    "jobs.ui.employmentType.APPRENTICE": "Apprentissage",
    "jobs.ui.employmentType.INTERN": "Stage",
    "jobs.ui.employmentType.CONTRACTOR": "Activité indépendante",
    "jobs.ui.employmentType.TRAINEE": "Stagiaire, études en cours d'emploi",
    "jobs.ui.employmentType.VOLUNTEER": "Bénévole",
    "jobs.ui.employmentType.OTHER": "",
    "jobs.ui.location": "Emploi disponible à",
    "jobs.ui.role.male": "M",
    "jobs.ui.role.female": "F",
    "jobs.ui.role.divers": "D",
    "jobs.ui.startDateNow": "Dès à présent",
    "jobs.ui.startFrom": "Tiré de",
    "name": "Nom",
    "profile.marketplace.request.missingCredentials": "Vous ne pouvez lancer une requête que lorsque vous avez créé au moins un lieu et un point de mesure.",
    "profile.newsletter.notSubscribedAny": "Vous n'êtes pas encore inscrit à une liste de diffusion.",
    "register.ui.privacyStatement": "J'accepte que mes données personnelles soient stockées. Mes données personnelles ne seront pas transmises à des tiers. Je peux révoquer ce consentement à la protection des données à tout moment avec effet pour l'avenir.",
    "register.ui.oauthInfo": "Vous serez redirigé vers la page de connexion du FRM pour vous connecter avec vos informations d'utilisateur, puis retourner sur le site.",
    "search.resetPosition": "Réinitialiser la position",
    "search.geoPositionBrowser": "Déterminer la position par le navigateur",
    "search.geoPositionFault": "La détermination de la position par le navigateur n'est pas possible",
    "search.geoPositionTitle": "Définir la position pour une recherche de proximité",
    "search.selectedFilters": "Filtres sélectionnés",
    "search.selectFilters": "Filtre d'affichage",
    "search.resultType.job": "Emploi",
    "search.resultType.recipe": "Recette",
    "social.dreamline.edited": "[objet Objet]",
    "user.company.addUser": "Créer des utilisateurs supplémentaires pour",
    "user.company.inviteUser": "Envoyer un courrier d'invitation",
    "user.company.userIsInvited": "Un courrier d'invitation a été envoyé",
    "user.company.userIsActive": "L'utilisateur est actif",
    "user.company.userIsNotActive": "L'utilisateur n'est pas encore activé",
    "user.company.contactFor": "Coordonnées de",
    "user.company.contactForDefault": "contact général",
    "user.company.notAllowed": "Vous ne pouvez pas faire de changements parce que votre statut d'utilisateur ({{type}}) ne le permet pas.",
    "user.company.registerNumber": "Numéro du registre du commerce",
    "user.company.registerAuth": "Tribunal local",
    "user.company.acerCode": "Code ACER de votre approvisionnement en énergie",
    "user.company.taxNumber": "Numéro d'identification fiscale",
    "user.company.type.default": "Standard",
    "user.company.type.procurator": "Signataire autorisé",
    "user.company.type.manager": "Directeur général",
    "user.company.ustid": "UStID",
    "user.company.ustid.notValid": "Ce numéro d'identification de la taxe sur les ventes n'est pas valable",
    "user.ui.accountMenuMeasuring": "Mes lieux de mesure et mes données",
    "user.ui.accountMenuOffer": "Mes offres",
    "user.ui.accountMenuRequest": "Mes demandes",
    "user.ui.accountMenuTender": "Appels d'offres",
    "user.ui.accountMenuOrders": "Mes commandes",
    "user.ui.accountMenuJobs": "Mes offres d'emploi",
    "user.ui.genderDiverse": "Divers",
    "user.ui.pleaseSelectSalutation": "Veuillez choisir la salutation",
    "user.ui.requestPasswordSuccess": "Vous devriez recevoir sous peu un courriel contenant un lien de réinitialisation.",
    "user.ui.requestPasswordError": "Erreur inconnue lors de la réinitialisation du mot de passe.",
    "user.ui.setPassword": "Définir le mot de passe",
    "user.ui.setPasswordPage": "Définissez votre mot de passe",
    "user.ui.setPasswordSuccess": "Votre mot de passe a été défini, vous pouvez vous connecter maintenant",
    "user.ui.salutation": "Salutation",
    "user.ui.title": "Titre",
    "user.ui.phone": "Numéro de téléphone",
    "order.status.zero": "commande reçue",
    "order.status.one": "En cours",
    "order.status.two": "La commande a été expédiée",
    "order.status.three": "Accès à",
    "order.status.four": "Retour",
    "order.status.default": "État d'occupation inconnu",
    "country.germany": "Allemagne",
    "country.de": "Allemagne",
    "country.austria": "Autriche",
    "country.at": "Autriche",
    "country.belgium": "Belgique",
    "country.denmark": "Danemark",
    "country.bulgaria": "Bulgarie",
    "country.croatia": "Croatie",
    "country.cyprus": "Chypre",
    "country.czechia": "République tchèque",
    "country.estonia": "Estonie",
    "country.finland": "Finlande",
    "country.france": "France",
    "country.fr": "France",
    "country.greece": "Grèce",
    "country.hungary": "Hongrie",
    "country.ireland": "Irlande",
    "country.italy": "Italie",
    "country.it": "Italie",
    "country.latvia": "Lettonie",
    "country.lithuania": "Lituanie",
    "country.luxembourg": "Luxembourg",
    "country.lu": "Luxembourg",
    "country.malta": "Malte",
    "country.netherlands": "Pays-Bas",
    "country.poland": "Pologne",
    "country.portugal": "Portugal",
    "country.romania": "Roumanie",
    "country.slovakia": "Slovaquie",
    "country.slovenia": "Slovénie",
    "country.spain": "Espagne",
    "country.sweden": "Suède",
    "country.norway": "Norvège",
    "country.switzerland": "Suisse",
    "country.ch": "Suisse",
    "country.turkey": "Turquie",
    "country.unitedKingdom": "Royaume-Uni",
    "country.gb": "Royaume-Uni",
    "openingHours.tNowClosed": "Maintenant fermé",
    "openingHours.tOpeningHoursNotKnown": "heures d'ouverture supplémentaires non connues",
    "openingHours.tNowOpen": "Maintenant ouvert",
    "openingHours.tNowStillOpen": "Maintenant encore ouvert",
    "openingHours.tNextOpeningHourUnknown": "prochaine date d'ouverture inconnue",
    "openingHours.tSoonOpenFrom": "ouvert à nouveau bientôt à partir de",
    "openingHours.tFrom": "à partir de",
    "openingHours.tUntil": "Par",
    "openingHours.tOpenAgainFrom": "ouvrir à nouveau de",
    "openingHours.tOpenAgainFrom2": "ouvert à nouveau le",

    // auto translated new
    "account.ui.additionalDeliveryAddresses": "Vous avez spécifié des adresses de livraison différentes.",
    "account.ui.editDeliveryAddresses": "Modifier l'adresse de livraison",
    "account.ui.editInvoiceAddress": "Modifier l'adresse de la société",
    "account.ui.noDeliveryAddresses": "Vous n'avez pas encore spécifié une autre adresse de livraison.",
    "account.ui.addDeliveryAddresses": "Ajouter un {{further}} Ajouter une adresse de livraison différente",
    "account.ui.addDeliveryAddressTitle": "Ajouter l'adresse de livraison",
    "account.ui.editDeliveryAddressTitle": "Modifier l'adresse de livraison",
    "account.ui.isDefaultAddress": "Il s'agit de l'adresse de livraison par défaut",
    "account.ui.saveToApplyChanges": "Vous devez sauvegarder la modification, puis vous pouvez, par exemple, spécifier des adresses de livraison différentes.",
    "application.noCosts": "Gratuit",
    "checkout.cart.orderCommitting": "Votre commande est en cours de traitement - veuillez patienter",
    "checkout.checkout.paymentProblem": "Une erreur s'est produite lors de l'exécution du paiement",
    "checkout.checkout.backToPayment": "Retour aux paramètres de paiement.",
    "user.ui.genderFemaleSalutation": "Mme.",
    "user.ui.genderMaleSalutation": "Monsieur.",
    "user.ui.thankYouForSubscribing": "Merci de vous inscrire à notre newsletter.",
    "user.ui.errorAtSubscribing": "Erreur lors de l'inscription à la newsletter",
    "user.ui.unsubscribe": "se déconnecter",
    "user.ui.unsubscribePage": "Êtes-vous sûr de vouloir vous désabonner de notre newsletter ?",
    "user.product.addedToFavorites": "L'article a été ajouté à vos favoris",

    // Tags
    "application.tag.neu": "Nouveau",
    "application.tag.sale": "Vente",
    "application.tag.eco": "Eco",
    "application.tag.sonderrabatt": "Remise spéciale",
    "job.application.selectLocation": "Choisis ta localisation",
    "job.application.location": "Disponible dans les régions suivantes"
  };
});