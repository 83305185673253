define('webapp/components/widgets/widget-script', ['exports', 'webapp/utils/misc'], function (exports, _misc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function handleScript(content) {
    (0, _misc.executeScriptsSrc)(content);
    (0, _misc.executeScripts)(content);
  }

  var loadedIds = [];

  exports.default = Ember.Component.extend({
    fastboot: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      if (this.get('fastboot.isFastBoot')) {
        return;
      }
      var widgetId = this.get('data._id');

      var content = this.get('data.content');
      var contentAfter = this.get('data.contentAfter');

      // execute scripts in contentAfter block only once
      if (contentAfter && !loadedIds.includes(widgetId)) {
        handleScript(contentAfter);
      }

      // execute scripts in content block every time the widget is loaded
      if (content) {
        handleScript(content);
      }

      loadedIds.push(widgetId);
    },
    willDestroyElement: function willDestroyElement() {
      if (window._mozScriptCleanup) {
        window._mozScriptCleanup();
      }
    }
  });
});