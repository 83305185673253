define("webapp/locales/es/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.entryBannerImageNew": "Establezca una imagen principal para su entrada.",
    "account.ui.profileBannerImageNew": "Establezca una imagen principal para su perfil.",
    "account.ui.register": "Registro",
    "account.ui.registeredAs": "Actualmente está registrado como",
    "account.ui.personate": "Cambiar la identidad del usuario",
    "account.ui.userNameNotEditableTooltip": "Su nombre de usuario. No puedes cambiar el nombre de usuario que has introducido. Para cambiar su nombre de usuario, póngase en contacto con su persona de contacto. Importante: Después de un cambio, debe volver a conectarse con el nuevo nombre de usuario.",
    "account.ui.additionalDeliveryAddresses": "Ha especificado diferentes direcciones de entrega.",
    "account.ui.editDeliveryAddresses": "Editar la dirección de entrega",
    "account.ui.editInvoiceAddress": "Editar la dirección de la empresa",
    "account.ui.noDeliveryAddresses": "Todavía no ha especificado una dirección de entrega diferente.",
    "account.ui.addDeliveryAddresses": "Añade un {{further}} Añadir una dirección de entrega diferente",
    "account.ui.addDeliveryAddressTitle": "Añadir dirección de entrega",
    "account.ui.editDeliveryAddressTitle": "Editar la dirección de entrega",
    "account.ui.isDefaultAddress": "Esta es la dirección de entrega por defecto",
    "account.ui.saveToApplyChanges": "Debe guardar el cambio, luego puede, por ejemplo, especificar diferentes direcciones de entrega.",
    "accountTypes.aussteller": "Expositor",
    "accountTypes.gastgeber": "Anfitrión",
    "accountTypes.veranstalter": "Organizador",
    "application.beta.infotext": "No se han creado suscripciones.",
    "application.breadcrumbsHome": "Inicio",
    "application.month": "mensualmente",
    "application.newsletter.alreadySubscribed": "Usted ya está suscrito al {{list}} boletín de noticias. Puede acceder a las suscripciones al boletín en su cuenta.",
    "application.newsletter.alreadySubscribedNoList": "Ya se ha inscrito en el boletín de noticias",
    "application.newsletter.goToList": "Lista de correo de llamadas",
    "application.newsletter.noAccess": "El boletín ofrecido no puede ser suscrito con sus permisos.",
    "application.newsletter.subscribeButton": "Introduzca su dirección de correo electrónico para suscribirse al boletín",
    "application.newsletter.subscribeButtonMobile": "E-mail",
    "application.newsletter.subscribe": "Suscríbase a",
    "application.newsletter.subscribeList": "Te suscribes a este boletín desde:",
    "application.newsletter.subscribeThanks": "Gracias por su suscripción al boletín. Recibirás un correo electrónico de confirmación para el opt-in.",
    "application.newsletter.subscribeWithAccount": "Suscríbase con su usuario actual.",
    "application.noCosts": "Gratis",
    "application.placeholderImageText": "Sin ilustración",
    "application.searchAllGroups": "en absoluto",
    "application.searchAllRegions": "en todos los lugares",
    "application.ui.access": "ir a",
    "application.ui.action": "Acción",
    "application.ui.addDocument": "Añadir documento",
    "application.ui.addRelation": "Añadir conexión",
    "application.ui.addImage": "Añadir imagen",
    "application.ui.addImages": "Añadir imágenes",
    "application.ui.addMedia": "Añadir imágenes/vídeos",
    "application.ui.addVideo": "Añadir vídeo",
    "application.ui.advancedEdit": "Mejorado",
    "application.ui.all": "Todo",
    "application.ui.alphabetical": "Alfabético",
    "application.ui.and": "y",
    "application.ui.at": "en",
    "application.ui.in": "en",
    "application.ui.atHour": "en",
    "application.ui.hour": "Ver",
    "application.ui.back": "volver",
    "application.ui.buy": "comprar",
    "application.ui.certificate": "Certificados",
    "application.ui.confirmDeleteEntry": "Borrar la entrada {{title}} ¿borrar la entrada de forma irrevocable?",
    "application.ui.contact": "Contacte con",
    "application.ui.count": "Número",
    "application.ui.currentlyActive": "En marcha ahora mismo",
    "application.ui.deleteEntry": "borrar",
    "application.ui.discardUnsavedChanges": "Descartar los cambios y continuar",
    "application.ui.discardUnsavedChangesMobile": "Descartar",
    "application.ui.distance": "Distancia",
    "application.ui.distant": "eliminado",
    "application.ui.dreampoints": "Puntos de sueño",
    "application.ui.dreampointsAllDreams": "Puntos de sueño de sus entradas",
    "application.ui.dreampointsDonate": "Puntos de sueño para donar",
    "application.ui.dreampointsTooltip": "Puedes acumular Dreampoints con tu entrada",
    "application.ui.edit": "Modificar",
    "application.ui.editDescription": "Editar descripción",
    "application.ui.editEntry": "editar",
    "application.ui.editEvent": "Editar evento",
    "application.ui.editImage": "Editar imagen",
    "application.ui.editPoi": "Editar ubicación",
    "application.ui.editProduct": "Editar producto",
    "application.ui.editProfile": "Editar mi perfil",
    "application.ui.editShownProfile": "Cambiar la visualización de mi perfil",
    "application.ui.entriesFocus": "Entradas seleccionadas en Focus:",
    "application.ui.entriesLatest": "Últimas entradas en Focus:",
    "application.ui.email": "Envíe un correo electrónico a",
    "application.ui.for": "para",
    "application.ui.fullView": "Vista general",
    "application.ui.gallery": "Imágenes",
    "application.ui.galleryImages": "a la galería...",
    "application.ui.gavePoints": "te dio {{points}} Te he dado puntos.",
    "application.ui.goToEntry": "Llamada de entrada",
    "application.ui.goToEvent": "Convocatoria del evento",
    "application.ui.goToPoi": "Ubicación de la llamada",
    "application.ui.goToMeasuring": "Puntos de medición",
    "application.ui.goToProduct": "Llamar al producto",
    "application.ui.hasBeen": "ha sido",
    "application.ui.here": "Cerca de",
    "application.ui.invalidEmbed": "Esta URL no puede ser incrustada.",
    "application.ui.imprintLink": "Responsable y jurídicamente responsable del contenido de esta subpágina {{entry}} - Aviso legal",
    "application.ui.isActive": "Activo",
    "application.ui.isNotActive": "Discapacitados",
    "application.ui.knowMore": "Aprende más sobre {{about}}",
    "application.ui.language.de": "Deutsch",
    "application.ui.language.en": "English",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "Italiano",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "Idioma seleccionado actualmente",
    "application.ui.language.switchTo": "Cambiar idioma a ",
    "application.ui.mailBodyPre": "Hola, he encontrado algo interesante:",
    "application.ui.mailSubject": "He encontrado un enlace interesante:",
    "application.ui.mandatory": "Campo obligatorio",
    "application.ui.memberSince": "Miembro desde",
    "application.ui.mobileRotate": "Gira para ver la pantalla completa",
    "application.ui.more": "más",
    "application.ui.name": "Nombre",
    "application.ui.navigateOneLevelUp": "Volver a la carpeta principal",
    "application.ui.new": "Nuevo",
    "application.ui.newEntry": "Crear una nueva entrada",
    "application.ui.newEvent": "Crear un nuevo evento",
    "application.ui.newPoi": "Crear una nueva ubicación",
    "application.ui.newProduct": "Crear un nuevo producto",
    "application.ui.noEntry": "Todavía no has creado una entrada. Cree su primera entrada ahora mismo...",
    "application.ui.noEntryProfile": "aún no ha creado una entrada.",
    "application.ui.noEvent": "Todavía no has creado un evento. Cree su primer evento ahora mismo...",
    "application.ui.noPoi": "Todavía no has creado una ubicación. Cree su primer local ahora mismo...",
    "application.ui.noJob": "Aún no has creado ningún puesto de trabajo. Cree su primer empleo ahora...",
    "application.ui.noProduct": "Todavía no ha creado un producto. Cree su primer producto ahora mismo...",
    "application.ui.notEditable": "no se puede cambiar",
    "application.ui.nothingFound": "No hemos podido encontrar ningún resultado para esta búsqueda.",
    "application.ui.offer": "Ofertas",
    "application.ui.only": "Sólo",
    "application.ui.or": "o",
    "application.ui.openDocument": "Abrir archivo",
    "application.ui.playVideo": "Reproducir vídeo",
    "application.ui.readMore": "Leer más",
    "application.ui.region": "Región",
    "application.ui.relevance": "Relevancia",
    "application.ui.reset": "Reiniciar",
    "application.ui.routingDescription": "Direcciones",
    "application.ui.saveShownProfile": "Guardar los cambios de perfil",
    "application.ui.saveUnsavedChanges": "Guardar",
    "application.ui.select": "Por favor, seleccione",
    "application.ui.shop": "Tienda",
    "application.ui.showAllEntries": "Mostrar todas las entradas",
    "application.ui.showAllSearch": "Mostrar todos los resultados",
    "application.ui.showMoreSearch": "Mostrar más resultados de búsqueda",
    "application.ui.resetSearch": "Restablecer la selección y buscar de nuevo",
    "application.ui.sort": "Clasificación",
    "application.ui.stockPhotoLinks": "Enlaces a bases de datos de imágenes",
    "application.ui.suitableFor": "Recomendado para",
    "application.ui.total": "Total",
    "application.ui.totalDreampoints": "Puntos de sueño de todas sus entradas",
    "application.ui.trySearch": "¿Quiere probar con otros términos de búsqueda, como por ejemplo",
    "application.ui.type": "Tipo",
    "application.ui.unsavedChanges": "Cambios no guardados",
    "application.ui.updated": "ACTUALIZADO",
    "application.ui.uploadedFileFor": "Archivo subido para:",
    "application.ui.userName": "Nombre de usuario",
    "application.ui.visibleForAll": "Visible para todos los usuarios",
    "application.ui.visibleForFollower": "Visible para los seguidores",
    "application.ui.visibleForJoiner": "Visible para Joiner",
    "application.ui.visibleForNone": "No es visible para nadie",
    "application.ui.visibleForSupporter": "Visible para los seguidores",
    "application.ui.writeUs": "escríbenos...",
    "application.ui.years": "Años",
    "application.ui.yearsFew": "Edad del bebé",
    "application.widgets.highestRatedEntries": "Los más Dreampoints",
    "application.widgets.inFocus": "En el punto de mira",
    "application.widgets.newestEntries": "Las últimas entradas",
    "blog.ui.category": "Categoría",
    "blog.ui.categoryHeading": "De la categoría",
    "blog.ui.lastUpdated": "Actualizado por última vez el",
    "blog.ui.openSinglePost": "Leer más",
    "blog.ui.publishedIn": "Publicado en",
    "blog.ui.subCategories": "Subcategorías",
    "blog.ui.written": "Publicado",
    "blog.ui.writtenBy": "Escrito por",
    "buttons.alreadyMeToo": "Me alegro de que también hayas soñado con esta entrada.",
    "buttons.alreadyMeTooMobile": "Me alegro de que también hayas soñado con esta entrada.",
    "buttons.cancel": "Cancelar",
    "buttons.confirm": "Confirmar",
    "buttons.createAccount": "Configurar EntryerAccount ahora",
    "buttons.delete": "borrar",
    "buttons.deleteFavorite": "No seguir",
    "buttons.deleteLike": "Ya no hay que comparar",
    "buttons.meToo": "Yo también quiero hacerlo",
    "buttons.newPassword": "Solicitar una nueva contraseña",
    "buttons.ok": "Vale",
    "buttons.ready": "Listo",
    "buttons.save": "Guardar los cambios",
    "buttons.saveMobile": "Guardar",
    "buttons.selectThis": "Seleccione este",
    "buttons.tryAgain": "Por favor, inténtelo de nuevo",
    "category.ui.hideCategories": "Ocultar subcategorías",
    "category.ui.oneLevelUp": "Volver a la categoría principal",
    "category.ui.showCategories": "Mostrar subcategorías",
    "categoryFields.attachmentWidth": "Implementar la anchura",
    "categoryFields.clutch": "Embrague",
    "categoryFields.companyName": "Nombre de la empresa",
    "categoryFields.dealerInfo": "Información del distribuidor",
    "categoryFields.diameter": "Diámetro",
    "categoryFields.displayNetPrice": "Precio neto",
    "categoryFields.electricalPower": "Energía eléctrica",
    "categoryFields.engine": "Motor",
    "categoryFields.engineDescription": "Equipo de motor",
    "categoryFields.ERPname": "Nombre en la gestión de mercancías",
    "categoryFields.horsePower": "Potencia",
    "categoryFields.netWidth": "Ancho de vía sin neumáticos",
    "categoryFields.packingUnit": "Unidad de embalaje",
    "categoryFields.PTO": "PTO",
    "categoryFields.relativeGauge": "Amplía la vía en",
    "categoryFields.vendorAcronym": "Nombre corto",
    "categoryFields.volume": "Volumen",
    "categoryFields.weight": "Peso",
    "categoryFields.wheelLock": "Bloqueo de la rueda del caballete",
    "checkout.cart.title": "Mi cesta de la compra",
    "checkout.cart.item": "Artículo",
    "checkout.cart.amount": "Número",
    "checkout.cart.edit": "Cambiar",
    "checkout.cart.price": "Precio",
    "checkout.cart.singlePrice": "Precio por unidad",
    "checkout.cart.product.isNonPhysical": "El producto no se enviará (puede estar disponible para su descarga)",
    "checkout.cart.product.requiresExpressDelivery": "Producto fresco. Debe ser enviado EXPRESS.",
    "checkout.cart.product.deliveryProblem": "El producto seleccionado puede sufrir retrasos en la entrega.",
    "checkout.cart.subtotal": "Subtotal",
    "checkout.cart.deliveryCosts": "Gastos de envío",
    "checkout.cart.total": "Total",
    "checkout.cart.help": "¿Tiene preguntas sobre el proceso de pedido? Hemos recopilado información importante para usted.",
    "checkout.cart.subtotalInfo": "IVA incluido",
    "checkout.cart.subtotalShipment": "más los gastos de envío desviados",
    "checkout.cart.checkout": "A la caja",
    "checkout.cart.checkoutWithoutUser": "Pago sin cuenta de cliente",
    "checkout.cart.checkoutFast": "Pago rápido",
    "checkout.cart.deliveryInfo": "Fecha estimada de entrega de los artículos de la cesta de la compra",
    "checkout.cart.orderSuccess": "Su pedido se ha realizado con éxito.",
    "checkout.cart.orderCommitting": "Su pedido está siendo procesado - por favor espere",
    "checkout.cart.myOrder": "Mi pedido",
    "checkout.checkout.orderNumber": "Número de pedido",
    "checkout.checkout.account": "Cuenta del cliente",
    "checkout.checkout.andOrder": "y el orden",
    "checkout.checkout.delivery": "Entrega y dirección de entrega",
    "checkout.checkout.delivery.type": "El envío se realiza con",
    "checkout.checkout.delivery.street": "Dirección línea 1",
    "checkout.checkout.delivery.houseNumber": "No.",
    "checkout.checkout.delivery.co": "Sufijo de la dirección (c/o)",
    "checkout.checkout.delivery.city": "Ciudad",
    "checkout.checkout.delivery.selectCountry": "Seleccione el país",
    "checkout.checkout.delivery.addressBoth": "Factura y dirección de entrega",
    "checkout.checkout.delivery.addressInvoice": "Dirección de la factura",
    "checkout.checkout.delivery.addressDelivery": "Dirección de entrega",
    "checkout.checkout.delivery.default": "Establecer esta dirección como mi dirección de entrega por defecto",
    "checkout.checkout.delivery.otherAddress": "La dirección de entrega difiere de la dirección de facturación",
    "checkout.checkout.delivery.dhlNr": "Su número de DHL Post",
    "checkout.checkout.delivery.packingStation": "Número de estación de embalaje",
    "checkout.checkout.delivery.showPackingStations": "Mostrar estaciones de embalaje cerca de mi dirección",
    "checkout.checkout.delivery.wantExpressShipping": "Entrega urgente",
    "checkout.checkout.delivery.isPickUp": "recogida in situ",
    "checkout.checkout.delivery.specialCountryInfo": "¿Su país no puede ser seleccionado?",
    "checkout.checkout.delivery.specialCountry": "Su país está fuera de nuestro envío estándar",
    "checkout.checkout.delivery.specialCountryMessage": "Los productos individuales de su cesta de la compra no pueden ser enviados a todos los países. Por favor, elimine algunos productos de su pedido hasta que se pueda seleccionar su país o póngase en contacto con nosotros.",
    "checkout.checkout.payment": "Forma de pago",
    "checkout.checkout.paymentProblem": "Se ha producido un error al ejecutar el pago",
    "checkout.checkout.backToPayment": "Vuelve a los ajustes de pago.",
    "checkout.checkout.paymentTitle": "¿Cómo piensa pagar?",
    "checkout.checkout.paymentSelected": "Método de pago seleccionado",
    "checkout.checkout.payment.cc": "Tarjeta de crédito",
    "checkout.checkout.payment.ccInfo": "Para los pagos con tarjeta dentro de la UE, su identidad debe ser confirmada cada vez que pague en línea. Después de la orden, se le guiará a través de la confirmación - proporcionada por su banco.",
    "checkout.checkout.payment.complimentary": "gratis",
    "checkout.checkout.payment.paypal": "PayPal",
    "checkout.checkout.payment.paypalInfo": "Será redirigido a PayPal para completar el proceso de pago. No se cargará en su cuenta de PayPal hasta que confirme el pedido en el siguiente paso.",
    "checkout.checkout.payment.sepa": "Adeudo directo (SEPA)",
    "checkout.checkout.payment.sepaLegal": "Autorizo {{siteOwner}} {{siteOwnerAddress}} (Identificación del acreedor: {{siteOwnerCreditorID}}) para cobrar los pagos de mi cuenta mediante domiciliación bancaria. Al mismo tiempo, instruyo a mi entidad de crédito para que pague los adeudos directos {{siteOwner}} a mi cuenta.",
    "checkout.checkout.payment.noSepa": "Su dirección de facturación o de envío está fuera de la UE. Por lo tanto, no podemos ofrecerle el pago por domiciliación bancaria.",
    "checkout.checkout.payment.sepaInfo": "Rápido y sin complicaciones. Al completar su pedido (siguiente paso) se le pedirá que introduzca sus datos bancarios (IBAN). Por favor, téngalos preparados. Cuando se complete la transferencia, recibirá una confirmación.",
    "checkout.checkout.payment.prepayment": "Pago anticipado (transferencia bancaria)",
    "checkout.checkout.payment.prepaymentInfo": "Una vez que haya realizado el pedido, le enviaremos la información sobre la transferencia bancaria por correo electrónico. Sólo podemos reservar los artículos de su pedido durante un máximo de 7 días. Por favor, mantenga esta ventana de tiempo para la transferencia bancaria. Tan pronto como recibamos el pago, se enviará el pedido.",
    "checkout.checkout.payment.invoice": "Factura",
    "checkout.checkout.payment.invoiceInfo": "El importe de la factura vence en la fecha de pago especificada en el correo electrónico de confirmación del envío.",
    "checkout.checkout.payment.noInvoice": "Lamentablemente, no podemos ofrecerle el pago por factura para este pedido. Por favor, elija uno de los otros métodos de pago.",
    "checkout.checkout.payment.sofort": "Sofortüberweisung",
    "checkout.checkout.payment.sofortInfo": "Al completar su pedido (siguiente paso) se le pedirá que introduzca sus datos bancarios (IBAN). Por favor, tenedlos preparados. Cuando se complete la transferencia, recibirá una confirmación.",
    "checkout.checkout.payment.accountName": "Titular de la cuenta",
    "checkout.checkout.payment.accountEmail": "Dirección de correo electrónico de confirmación",
    "checkout.checkout.sepaAndOrder": "Mandato de domiciliación bancaria para {{totalAmount}} Confirmar y ordenar con gastos",
    "checkout.checkout.sofortAndOrder": "Mandato de transferencia para {{totalAmount}} Confirmar y ordenar con gastos",
    "checkout.checkout.title": "Comprobación",
    "checkout.checkout.next": "Leer más",
    "checkout.checkout.order": "Comprar ahora",
    "checkout.checkout.orderConsentPre": "Con la orden se explican con nuestro",
    "checkout.checkout.orderConsentPost": "acordado.",
    "checkout.checkout.noAccount": "No crear una cuenta de cliente",
    "checkout.redirect.countryPriceChange": "Los precios son diferentes en su país, por favor tome nota del cambio de precio.",
    "comments.blockComments": "Se han cerrado los comentarios para esta entrada.",
    "comments.comment.anonymous": "Anónimo",
    "comments.comment.button": "Publicar un nuevo comentario",
    "comments.comment.buttonReply": "Respuesta",
    "comments.comment.cancel": "Cancelar",
    "comments.comment.content": "Su comentario",
    "comments.comment.hasCommented": "Comentarios",
    "comments.comment.login": "Inicie sesión para publicar un comentario",
    "comments.comment.past": "tiene",
    "comments.comment.replyFrom": "Respuesta a",
    "comments.comment.showReplies": "Mostrar respuestas",
    "comments.comment.showReply": "Mostrar respuesta",
    "comments.comment.submit": "Enviar",
    "comments.comment.title": "Título del comentario",
    "comments.comment.userName": "su nombre de usuario",
    "comments.heading": "Sugerencias, notas, comentarios",
    "comments.hierarchy.flat": "Plano",
    "comments.hierarchy.nested": "Genested",
    "comments.hierarchy.title": "Ver comentarios:",
    "comments.message.afterSaveError": "Su comentario no puede ser guardado en este momento!",
    "comments.message.afterSaveSuccess": "Tu comentario ha sido guardado.",
    "comments.message.afterSaveUnlock": "Tu comentario ha sido guardado, pero aún debe ser revisado por un administrador.",
    "comments.noComments": "Todavía no se ha presentado ningún comentario",
    "comments.reply": "AN:",
    "comments.show": "Mostrar comentarios",
    "comments.title": "Comentarios",
    "entries.edit": "Entrada <strong>{{title}}</strong> editar",
    "entries.entries": "Entradas",
    "entries.entry.fields.address.city": "Ciudad",
    "entries.entry.fields.address.country": "País",
    "entries.entry.fields.address.houseNumber": "Número de casa",
    "entries.entry.fields.address.street": "Calle",
    "entries.entry.fields.address.zip": "CÓDIGO POSTAL",
    "entries.entry.fields.language": "Idioma",
    "entries.entry.fields.prefixMissing": "Los siguientes campos no están rellenados:",
    "entries.entry.profile.edit": "Editar perfil",
    "entries.entry.profile.publicProfile": "a mi página de perfil público",
    "entries.entry.tooltip.alreadyCopied": "Ya estás soñando con esta entrada.",
    "entries.entry.tooltip.alreadyFollow": "Ya estás siguiendo esta entrada",
    "entries.entry.tooltip.alreadyLiked": "Ya te gusta esta entrada",
    "entries.entry.tooltip.copied": "Me alegro de que tú también quieras soñar con esta entrada. Tu foto de perfil ha sido añadida a esta entrada.",
    "entries.entry.tooltip.filterComments": "Haga clic aquí para mostrar sólo los comentarios en la línea de entrada",
    "entries.entry.tooltip.filterFollows": "Haga clic aquí para mostrar sólo Seguimientos en la Línea de Entrada",
    "entries.entry.tooltip.filterLikes": "Haga clic aquí para mostrar sólo los gustos en la línea de entrada",
    "entries.entry.tooltip.filterShares": "Haga clic aquí para mostrar sólo las acciones en la línea de entrada",
    "entries.entry.tooltip.follow": "Haga clic aquí para seguir esta entrada",
    "entries.entry.tooltip.like": "Haga clic aquí para dar un \"Me gusta\" a esta entrada",
    "entries.entry.tooltip.noFollow": "Follow sólo funciona para las entradas extranjeras",
    "entries.entry.tooltip.noFollowNotLoggedIn": "Debes estar conectado para seguir esta entrada",
    "entries.entry.tooltip.noLike": "Como sólo funciona para las entradas de otras personas",
    "entries.entry.ui.access": "Sólo las siguientes personas pueden ver esta entrada",
    "entries.entry.ui.added": "...se añadió.",
    "entries.entry.ui.addEntry": "Añadir entradas",
    "entries.entry.ui.addOpening": "Añadir hora de apertura",
    "entries.entry.ui.address": "Datos de la dirección",
    "entries.entry.ui.addStep": "Añadir un paso",
    "entries.entry.ui.agreementHours": "Abierto por acuerdo",
    "entries.entry.ui.allAccess": "Todos pueden ver esta entrada",
    "entries.entry.ui.assignedCategories": "categorías asignadas",
    "entries.entry.ui.assignedCertificates": "Mis certificaciones",
    "entries.entry.ui.assignedProducts": "productos asignados",
    "entries.entry.ui.businessContact": "Datos de contacto de la empresa",
    "entries.entry.ui.categories": "Categorías para esta entrada",
    "entries.entry.ui.categoryFields": "Más información",
    "entries.entry.ui.commercialTitle": "Información de la empresa en la entrada",
    "entries.entry.ui.companyTitle": "Datos de mi empresa",
    "entries.entry.ui.contactPerson": "Mis datos de contacto",
    "entries.entry.ui.cssClass": "Clase CSS para el contenido",
    "entries.entry.ui.deliveryInfo": "Información sobre el servicio de entrega",
    "entries.entry.ui.descriptionInfo": "Consejo: Cuando las palabras están resaltadas, se pueden asignar estilos.",
    "entries.entry.ui.descriptionPlaceholder": "Descripción con HTML. Haga doble clic en el texto para la barra de herramientas HTML (máximo 600 caracteres).",
    "entries.entry.ui.editEntry": "Editar entrada",
    "entries.entry.ui.editEntryNew": "Crear entrada",
    "entries.entry.ui.exclusionHours": "Tiempos de exclusión",
    "entries.entry.ui.galleryImages": "Galería de fotos (2 o 3 fotos según la disposición)",
    "entries.entry.ui.globalTitle": "Nombre",
    "entries.entry.ui.hasKiosk": "Tengo un puesto",
    "entries.entry.ui.imagesHorizontal": "Horizontal",
    "entries.entry.ui.imagesInfo": "Las imágenes se pueden editar en \"Imágenes/Vídeos/Documentos\".",
    "entries.entry.ui.imagesOrientation": "Alineación de imágenes",
    "entries.entry.ui.imagesSquared": "Cuadrado",
    "entries.entry.ui.imagesVertical": "Vertical",
    "entries.entry.ui.isListImage": "Esta imagen es la de la lista",
    "entries.entry.ui.isMainImage": "Esta imagen es la principal",
    "entries.entry.ui.isMainImageAndListImage": "Esta imagen aparece como imagen principal de la entrada",
    "entries.entry.ui.kioskDescription": "Descripción de su stand",
    "entries.entry.ui.listImage": "Pantalla de la lista",
    "entries.entry.ui.logo": "Logotipo",
    "entries.entry.ui.longDescription": "Texto de descripción largo",
    "entries.entry.ui.mainImage": "Imagen principal",
    "entries.entry.ui.makeListImage": "Establezca esta imagen como imagen de la lista",
    "entries.entry.ui.makeListImageTooltip": "Los resultados de la búsqueda se muestran como una lista con la imagen de la lista. Si no se selecciona ninguna imagen de la lista, se utiliza la imagen principal.",
    "entries.entry.ui.makeMainImage": "Establezca esta imagen como imagen principal",
    "entries.entry.ui.makeMainImageTooltip": "La imagen principal aparece en la parte superior del perfil. Las imágenes que no se seleccionan como imagen principal o de lista se muestran en la galería de imágenes.",
    "entries.entry.ui.metaDescription": "Breve descripción",
    "entries.entry.ui.metaDescriptionPlaceholder": "Descripción breve con un máximo de 200 caracteres",
    "entries.entry.ui.multiCode": "Código adicional para este paso",
    "entries.entry.ui.multiDescription": "Descripción de este paso",
    "entries.entry.ui.multiImages": "Imágenes para este paso",
    "entries.entry.ui.multiProgress": "Progreso",
    "entries.entry.ui.multiTitle": "Título de este paso",
    "entries.entry.ui.multiTitlePlaceholder": "Título para este paso (máximo 50 caracteres)",
    "entries.entry.ui.noDescriptions": "La entrada aún no tiene texto descriptivo.",
    "entries.entry.ui.noEntries": "No se han encontrado entradas.",
    "entries.entry.ui.onlyVisibleForCustomers": "Esta entrada sólo es visible para los clientes registrados",
    "entries.entry.ui.openAt": "Abrir",
    "entries.entry.ui.openingHours": "Horarios de apertura",
    "entries.entry.ui.openingInfo": "Notas sobre los horarios de apertura",
    "entries.entry.ui.openingReligious": "Dirección en parroquia predominantemente católica",
    "entries.entry.ui.overallProgress": "Esta entrada es {{value}}% realizado",
    "entries.entry.ui.parking": "Aparcamiento",
    "entries.entry.ui.parkingLots": "Número",
    "entries.entry.ui.parkingType": "Arte",
    "entries.entry.ui.personImage": "Foto de la persona de contacto",
    "entries.entry.ui.relatedEvents": "Eventos relacionados",
    "entries.entry.ui.relatedJobs": "Trabajos relacionados",
    "entries.entry.ui.relatedArticles": "Artículos relacionados",
    "entries.entry.ui.relatedCampaigns": "Campañas relacionadas",
    "entries.entry.ui.rentKiosk": "Me gustaría alquilar un puesto de venta",
    "entries.entry.ui.resolveAddress": "Determinar las coordenadas",
    "entries.entry.ui.resolveAddressInfo": "Por favor, determine el geopunto (posición en el mapa) de su dirección. Sin el geopunto correcto, es posible que no se encuentren correctamente.",
    "entries.entry.ui.resolveGeolocation": "Obtener la dirección",
    "entries.entry.ui.routingPlaceholder": "Introduzca aquí sus direcciones con HTML.",
    "entries.entry.ui.selectEvent": "Seleccione el evento",
    "entries.entry.ui.selectJob": "Seleccione el trabajo",
    "entries.entry.ui.selectArticle": "Seleccione el artículo",
    "entries.entry.ui.selectCampaign": "Seleccione la campaña",
    "entries.entry.ui.shortDescriptionInfo": "Es al mismo tiempo la descripción corta",
    "entries.entry.ui.sharing": "Comparte esta página en {{sharing}}",
    "entries.entry.ui.shareMail": "Compartir esta página por correo electrónico",
    "entries.entry.ui.skillsNeeded": "Para mi entrada, me vendría muy bien ....",
    "entries.entry.ui.sortword": "Títulos alternativos para la clasificación",
    "entries.entry.ui.step": "Paso",
    "entries.entry.ui.stepInDream": "¿En qué fase de la entrada se encuentra este paso?",
    "entries.entry.ui.stepInDreamAfter": "después de la realización",
    "entries.entry.ui.stepInDreamBefore": "antes de la realización",
    "entries.entry.ui.stepInDreamDuring": "durante la realización",
    "entries.entry.ui.subtitle": "Subtítulo descriptivo",
    "entries.entry.ui.subtitlePlaceholder": "Introduzca el subtítulo (máximo 60 caracteres)",
    "entries.entry.ui.tabCategories": "Categorías",
    "entries.entry.ui.tabCategoriesMobile": "Cat.",
    "entries.entry.ui.tabCompany": "Datos de la empresa",
    "entries.entry.ui.tabDescription": "Descripción",
    "entries.entry.ui.tabDescriptionMobile": "Beschr.",
    "entries.entry.ui.tabExhibitor": "Datos del expositor",
    "entries.entry.ui.tabImages": "Fotos/Vídeos/Documentos",
    "entries.entry.ui.tabImagesMobile": "Fotos/Vídeos/Archivos",
    "entries.entry.ui.tabRelations": "Conexiones",
    "entries.entry.ui.tags": "Etiquetas:",
    "entries.entry.ui.title": "La entrada aparece con el siguiente nombre",
    "entries.entry.ui.titleError": "La entrada debe ser nombrada antes de guardar",
    "entries.entry.ui.titlePlaceholder": "Introduzca el título (máximo 50 caracteres)",
    "entries.entry.ui.visible": "Esta entrada es visible",
    "entries.entry.ui.promotion": "Participación en acciones para esta entrada",
    "entries.entry.ui.regions": "Categorías regionales para esta entrada",
    "entries.job.ui.promotion": "Participación en acciones para este trabajo",
    "entries.event.edit": "Evento <strong>{{title}}</strong> editar",
    "entries.event.ui.addEvent": "Añadir evento",
    "entries.event.ui.cooperation": "Socio colaborador",
    "entries.event.ui.directions": "Direcciones",
    "entries.event.ui.editEvent": "Editar evento",
    "entries.event.ui.editEventNew": "Crear un evento",
    "entries.event.ui.endDate": "Fin del evento (día)",
    "entries.event.ui.eventDone": "Informe de la feria",
    "entries.event.ui.noEvents": "No se han encontrado eventos",
    "entries.event.ui.openEvent": "Convocatoria del evento",
    "entries.event.ui.opening": "Horarios de apertura",
    "entries.event.ui.startDate": "Inicio del evento (día)",
    "entries.event.ui.tabCategories": "Categorías",
    "entries.event.ui.tabDescription": "Descripción",
    "entries.event.ui.tabOpening": "Fecha y horarios",
    "entries.event.ui.tabRelations": "Conexiones",
    "entries.event.ui.title": "El evento aparece con los siguientes nombres",
    "entries.event.ui.upcoming": "En pocas palabras",
    "entries.event.ui.moreEvents": "Otros eventos",
    "entries.open": "Entrada de llamadas en el frontend",
    "entries.person.ui.company": "Empresa",
    "entries.person.ui.female": "La Sra.",
    "entries.person.ui.diverse": "Sin",
    "entries.person.ui.fullName": "Nombre y apellidos",
    "entries.person.ui.fullSize": "Tamaño completo",
    "entries.person.ui.gender": "Género",
    "entries.person.ui.greeting": "Saludos",
    "entries.person.ui.male": "El Sr.",
    "entries.person.ui.open": "Directamente a la entrada de esta persona",
    "entries.person.ui.personAddress": "Dirección de la persona",
    "entries.person.ui.personContact": "Datos personales de contacto",
    "entries.person.ui.personImage": "Imagen personal",
    "entries.person.ui.position": "Posición",
    "entries.person.ui.quote": "Cita/capítulo",
    "entries.person.ui.signature": "Imagen de la firma",
    "entries.poi.ui.poiEvents": "Eventos en este lugar",
    "entries.poi.ui.title": "El lugar aparece con los siguientes nombres",
    "entries.product.edit": "Producto <strong>{{title}}</strong> editar",
    "entries.product.fields.engine": "Motor",
    "entries.product.fields.horsePower": "Potencia",
    "entries.product.menu.allAttachments": "Todos los archivos adjuntos en el resumen",
    "entries.product.menu.allCarriers": "Todos los vehículos de transporte en el resumen",
    "entries.product.menu.attachments": "Archivos adjuntos",
    "entries.product.menu.carriers": "Vehículos de transporte",
    "entries.product.tags.eco": "eco",
    "entries.product.tags.neu": "Nuevo",
    "product.ui.content": "Contenido",
    "product.ui.ingredients": "Componentes",
    "product.ui.ingredientsFood": "Ingredientes",
    "product.ui.nonPhysical": "Descargar el producto electrónico",
    "entries.product.ui.addProduct": "Añadir producto",
    "entries.product.ui.choose": "seleccione",
    "entries.product.ui.discount": "Descuento",
    "entries.product.ui.discountSelect": "% de ventaja de descuento",
    "entries.product.ui.equipmentVariants": "Variantes de equipamiento",
    "entries.product.ui.variants": "Variantes",
    "entries.product.ui.gauge": "Anchura en cm",
    "entries.product.ui.gaugeMin": "Ancho de vía mínimo en cm",
    "entries.product.ui.inclusive": "Incluye",
    "entries.product.ui.live": "{{name}} experiencia en vivo",
    "entries.product.ui.netPrize": "inclusivo {{tax}}% de impuesto sobre el valor añadido, más",
    "entries.product.ui.netPrizeDefault": "incluido el impuesto sobre el valor añadido, más",
    "entries.product.ui.deliveryTerms": "Gastos de envío",
    "entries.product.ui.noProducts": "No se han encontrado productos",
    "entries.product.ui.noPurchaseOnThisPage": "No se puede pedir en esta página, llame al producto para hacerlo.",
    "entries.product.ui.onlyBasicVariant": "Sólo en equipos básicos",
    "entries.product.ui.onlyWithCarrier": "Sólo disponible con la compra de una unidad básica",
    "entries.product.ui.order": "Añadir a la cesta de la compra",
    "entries.product.ui.notOrderable": "Seleccione una variante.",
    "entries.product.ui.overview": "Resumen",
    "entries.product.ui.prizeDecimalPoint": ",",
    "entries.product.ui.prizeFrom": "de",
    "entries.product.ui.item": "Pieza",
    "entries.product.ui.prizePerItem": "Precio por pieza",
    "entries.product.ui.noPrize": "Consulte el precio con su persona de contacto.",
    "entries.product.ui.size": "Tamaño",
    "entries.product.ui.required": "Sólo con",
    "entries.product.ui.suitableFor": "Adecuado para",
    "entries.product.ui.suitableForAttachments": "Adecuado para los siguientes accesorios",
    "entries.product.ui.suitableForCarriers": "Adecuado para los siguientes vehículos de transporte",
    "entries.product.ui.title": "Producto",
    "entries.product.ui.variant": "Variante/paquete",
    "entries.product.ui.variantNotOrderable": "La variante seleccionada no se puede pedir",
    "entries.product.ui.chooseVariant": "Seleccione una variante",
    "entries.product.ui.chooseVariantMobile": "Seleccionar variante",
    "entries.product.ui.combinationNotAvailable": "Combinación no disponible",
    "entries.progress.title": "Cómo mejorar su entrada",
    "entries.tabbar.basics": "Datos básicos",
    "entries.tabbar.entry": "Datos de entrada",
    "entries.tabbar.gallery": "Imágenes",
    "entries.tabbar.layout": "Diseño",
    "entries.tabbar.openingHours": "Horarios de apertura",
    "entries.tabbar.order": "Información sobre pedidos",
    "entries.tabbar.tooltip.entryNotSavedDetail": "Los datos de la entrada sólo pueden editarse después de haber guardado la entrada con el título.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "Los datos del diseño sólo pueden editarse después de que la entrada se haya guardado con un título.",
    "entries.types.poi": "Poi",
    "entries.types.standard": "Estándar",
    "entries.ui.groupDetailName": "Nombre para las entradas del grupo",
    "entries.ui.groupName": "Nombre para los grupos (región)",
    "entryTypes.entry": "Entrada",
    "entryTypes.event": "Evento",
    "entryTypes.poi": "Lugar de celebración",
    "errors.blank": "{{description}} debe ser completado",
    "errors.description": "Este campo",
    "errors.email": "{{description}} debe ser una dirección de correo electrónico válida",
    "errors.empty": "{{description}} no debe estar vacío",
    "errors.exclusion": "{{description}} ya se ha hablado de ello",
    "errors.inclusion": "{{description}} no se incluye en la selección",
    "errors.invalid": "{{description}} es nulo",
    "errors.mandatory": "{{field}} debe ser completado",
    "errors.mandatoryCheckbox": "Debe ser confirmado.",
    "errors.mandatorySubmit": "Sólo podrá enviar el formulario cuando haya rellenado todos los campos obligatorios.",
    "errors.slug": "La babosa sólo puede contener letras minúsculas, números y \"-\".",
    "forms.ui.deleteFile": "Eliminar el archivo",
    "forms.ui.formErr": "Error al enviar el formulario",
    "forms.ui.formSent": "Formulario enviado",
    "forms.ui.noForm": "No hay forma asignada",
    "forms.ui.invalidMail": "Por favor, introduzca una dirección de correo electrónico válida.",
    "forms.ui.isSubmitted": "El formulario ha sido enviado. Nos pondremos en contacto con usted lo antes posible.",
    "forms.ui.missingFields": "Se deben rellenar los siguientes campos:",
    "forms.ui.uploadSuccess": "{{file}} ha sido cargado",
    "forms.ui.fieldBuilder.street": "Calle",
    "forms.ui.fieldBuilder.houseNumber": "No.",
    "forms.ui.fieldBuilder.zip": "CÓDIGO POSTAL",
    "forms.ui.fieldBuilder.city": "Ciudad",
    "forms.ui.fieldBuilder.country": "País",
    "forms.ui.fieldBuilder.firstName": "Nombre de pila",
    "forms.ui.fieldBuilder.lastName": "Apellido",
    "forms.ui.privacyRead": "He tomado nota de la política de privacidad",
    "jobs.ui.employmentType.FULL_TIME": "A tiempo completo",
    "jobs.ui.employmentType.PART_TIME": "A tiempo parcial",
    "jobs.ui.employmentType.APPRENTICE": "Educación",
    "jobs.ui.employmentType.INTERN": "Prácticas",
    "jobs.ui.employmentType.CONTRACTOR": "Trabajo autónomo",
    "jobs.ui.employmentType.TRAINEE": "Aprendiz, estudio a tiempo parcial",
    "jobs.ui.employmentType.VOLUNTEER": "Voluntario",
    "jobs.ui.employmentType.OTHER": "",
    "jobs.ui.location": "Trabajo disponible en",
    "jobs.ui.role.male": "M",
    "jobs.ui.role.female": "W",
    "jobs.ui.role.divers": "D",
    "jobs.ui.startDateNow": "A partir de ahora",
    "jobs.ui.startFrom": "Desde",
    "media.documents.search.noResult": "Introduzca un número de serie o seleccione una categoría.",
    "media.documents.search.placeholder": "Buscar el número de serie o el número de tipo del dispositivo",
    "media.documents.search.reset": "Restablecer el resultado de la búsqueda",
    "media.ui.copyright": "Copyright",
    "media.ui.cropFormat": "Especificación del formato",
    "media.ui.cropImage": "Recorte",
    "media.ui.cropImageDuplicate": "Duplicar y recortar",
    "media.ui.description": "Descripción",
    "media.ui.filename": "Nombre del archivo",
    "media.ui.imageSaved": "Imagen guardada",
    "media.ui.processingImage": "La imagen está siendo recortada, por favor espere",
    "media.ui.resetCrop": "Reajustar el corte",
    "media.ui.resetImage": "Volver al original",
    "media.ui.rotateImage": "Girar la imagen 90° en el sentido de las agujas del reloj",
    "media.ui.title": "Título",
    "name": "Nombre",
    "openingHours.additionalInfo": "Información sobre los horarios de apertura",
    "openingHours.addOpening": "Añadir hora de apertura",
    "openingHours.date.day.di": "Mar",
    "openingHours.date.day.do": "Haga",
    "openingHours.date.day.fr": "Vie",
    "openingHours.date.day.mi": "Mi",
    "openingHours.date.day.mo": "Mon",
    "openingHours.date.day.sa": "Sat",
    "openingHours.date.day.so": "Así que",
    "openingHours.dateS": "Fecha",
    "openingHours.delete": "Borrar",
    "openingHours.from": "Desde",
    "openingHours.options": "Área",
    "openingHours.time": "Tiempo",
    "openingHours.to": "Por",
    "openingHours.type": "Tipo de horario de apertura",
    "opt-in.error-can-not-update-member": "Los cambios en los datos de los socios para {{email}} no pudo llevarse a cabo.",
    "opt-in.error-list-does-not-exist": "No se ha encontrado ninguna lista para las fechas especificadas.",
    "opt-in.error-list-does-not-need-validation": "La lista especificada no tiene opt-int.",
    "opt-in.error-member-not-in-list": "No se ha encontrado ningún miembro de una lista para las fechas especificadas.",
    "opt-in.success-member-verified": "El {{email}} se añadió a la {{listname}} Añadido a la lista.",
    "opt-out.error-can-not-update-member": "Los cambios en los datos de los socios para {{email}} no pudo llevarse a cabo.",
    "opt-out.error-list-does-not-exist": "No se ha encontrado ninguna lista para las fechas especificadas.",
    "opt-out.error-list-does-not-have-opt-out": "La lista especificada no tiene una opción de exclusión.",
    "opt-out.error-member-not-in-list": "No se ha encontrado ningún miembro de una lista para las fechas especificadas.",
    "opt-out.success-membership-terminated": "El {{email}} fue eliminado del {{listname}} eliminado.",
    "password.errors.not-secure-enough": "Recomendamos una contraseña con una longitud de al menos 8 caracteres, con letras mayúsculas y minúsculas, números y caracteres especiales ($@$!%*#?&amp;).",
    "password.errors.password-and-conf-do-not-match": "Las dos contraseñas deben ser idénticas.",
    "password.quality.0": "Muy mal",
    "password.quality.1": "Malas",
    "password.quality.2": "Suficiente",
    "password.quality.3": "Bien",
    "password.quality.4": "Muy bueno",
    "profile.entries.copycats": "Entradas que {{userName}} también quiere hacer",
    "profile.entries.donates": "Entradas que {{userName}} soporta",
    "profile.entries.entries": "Entradas de {{userName}}",
    "profile.entries.follows": "Entradas que {{userName}} sigue",
    "profile.entries.joins": "Entradas en las que {{userName}} participa",
    "profile.marketplace.fields.energyRequired": "demanda de electricidad",
    "profile.marketplace.fields.livingAnimals": "Toma de animales vivos",
    "profile.marketplace.fields.loadingUnloading": "Necesario para la llegada/salida",
    "profile.marketplace.fields.maxRange": "Para mi oferta ya tengo tantos km para conducir",
    "profile.marketplace.fields.placeRequired": "Requisitos para el terreno de juego",
    "profile.marketplace.fields.securityRequired": "Necesidad de seguridad",
    "profile.marketplace.fields.shows": "Oferta del programa",
    "profile.marketplace.fields.spaceRequired": "Espacio necesario en m²",
    "profile.marketplace.fields.waterRequired": "Demanda de agua en m³",
    "profile.marketplace.new": "Nueva oferta o demanda",
    "profile.marketplace.offer.delete": "Borrar",
    "profile.marketplace.offer.description": "Descripción",
    "profile.marketplace.offer.descriptionPlaceholder": "Descripción significativa para que otro usuario pueda hacer algo con ella.",
    "profile.marketplace.offer.new": "Crear una nueva oferta",
    "profile.marketplace.offer.relatedEntry": "Entrada asignada",
    "profile.marketplace.offer.tags": "Etiquetas para la oferta/solicitud (se encontrará mejor)",
    "profile.marketplace.offer.title": "Ofrezco",
    "profile.marketplace.request.delete": "Eliminar la solicitud",
    "profile.marketplace.request.new": "Crear una nueva solicitud",
    "profile.marketplace.request.missingCredentials": "Sólo puede iniciar una consulta cuando haya creado al menos una ubicación y un punto de medición.",
    "profile.marketplace.request.title": "Necesito",
    "profile.messages.approve": "Confirmar",
    "profile.messages.as": "como",
    "profile.messages.commentPre": "tiene",
    "profile.messages.commentSuff": "Comentarios",
    "profile.messages.decided": "decidió hacerlo",
    "profile.messages.deletedUser": "Usuario eliminado",
    "profile.messages.donateDream": "Muchas gracias por ayudar a este participante con consejos y apoyo para realizar su entrada. Recibirá tu mensaje inmediatamente y es de esperar que te responda y acepte tu ayuda.",
    "profile.messages.dreamer": "Un solo transportista",
    "profile.messages.dreamToo": "también para soñar",
    "profile.messages.enter": "Se unió a",
    "profile.messages.favoriteDream": "Ahora estás siguiendo esta entrada.",
    "profile.messages.follow": "Sincronización y correcciones por n17t01",
    "profile.messages.has": "ha sucedido",
    "profile.messages.is": "Sincronización y correcciones por n17t01",
    "profile.messages.join": "ÚNASE A",
    "profile.messages.joinDream": "Quieres soñar con esta entrada.",
    "profile.messages.likeDream": "Ahora te gusta esta entrada.",
    "profile.messages.sendText": "Su mensaje fue enviado.",
    "profile.messages.shareDream": "Has compartido con éxito esta entrada.",
    "profile.messages.supporter": "Partidarios",
    "profile.messages.want": "quisiera",
    "profile.messages.youAre": "Usted es",
    "profile.messages.youWant": "Le gustaría",
    "profile.newsletter.notSubscribed": "suscríbase a",
    "profile.newsletter.notSubscribedAny": "Todavía no se ha suscrito a una lista de boletines.",
    "profile.newsletter.privacyInformation": "Al hacer clic en \"suscribir\", acepta que le enviemos futuros boletines de esta suscripción (opt-in). Puede darse de baja del boletín en cualquier momento haciendo clic de nuevo en \"suscribir\" (opt-out).",
    "profile.newsletter.subscribed": "suscrito",
    "profile.press.articleDetail": "Detalle del artículo",
    "profile.press.downloadImage": "Descargar imagen",
    "profile.press.downloadImages": "Descargar imágenes",
    "profile.press.downloadText": "Exportar texto",
    "profile.press.filterAuthor": "Filtrar por autor",
    "profile.press.hide": "Desvanecimiento",
    "profile.press.show": "desvanecerse",
    "profile.progress.title": "Cómo mejorar su perfil",
    "register.ui.addressData": "Datos de la dirección",
    "register.ui.addressInfo": "No tiene que introducir su dirección personal. Sin embargo, esto conduce a un cálculo correcto de la ruta y la distancia.",
    "register.ui.companyData": "los datos de su empresa",
    "register.ui.companyDataMandatory": "Debe introducir el nombre de su empresa",
    "register.ui.emptyFirstName": "Debe introducir su nombre de pila",
    "register.ui.emptyLastName": "Debe introducir su apellido",
    "register.ui.emptyMail": "Debe proporcionar una dirección de correo electrónico válida",
    "register.ui.emptyUserName": "Debe especificar un nombre de usuario",
    "register.ui.language": "Idioma",
    "register.ui.loginData": "Datos de acceso",
    "register.ui.password": "Contraseña",
    "register.ui.passwordRepeat": "Repite la contraseña",
    "register.ui.personalData": "Sus datos personales",
    "register.ui.privacyStatement": "Acepto que mis datos personales sean almacenados. Mis datos personales no se transmitirán a terceros. Puedo revocar este consentimiento de protección de datos en cualquier momento con efecto para el futuro.",
    "register.ui.takenEmail": "Este correo electrónico ya ha sido especificado por un usuario.",
    "register.ui.takenUserName": "Este nombre de usuario ya está ocupado",
    "register.ui.timezone": "Zona horaria",
    "register.ui.typeMinimum": "Debe seleccionar al menos un tipo",
    "register.ui.usernameInfo": "Importante: Este es su nombre de usuario para el inicio de sesión",
    "register.ui.oauthInfo": "Se le redirigirá a la página de inicio de sesión de FRM para que inicie la sesión con sus datos de usuario y, a continuación, vuelva al sitio web.",
    "search.allDistances": "Todas las distancias",
    "search.andFilter": "y los ajustes de los filtros",
    "search.closeTags": "Ocultar etiquetas",
    "search.for": "a",
    "search.resetPosition": "Restablecer posición",
    "search.geoPositionBrowser": "Determinar la posición por navegador",
    "search.geoPositionFault": "La determinación de la posición por parte del navegador no es posible",
    "search.geoPositionTitle": "Fijar la posición para una búsqueda de proximidad",
    "search.selectedFilters": "Filtros seleccionados",
    "search.selectFilters": "Filtrar la búsqueda por categoría",
    "search.hits": "Golpea",
    "search.openTags": "Mostrar etiquetas",
    "search.removeDatefilter": "Eliminar el filtro de fechas",
    "search.resulted": "revelado",
    "search.resultType.category": "Categoría",
    "search.resultType.entry": "Estudio",
    "search.resultType.event": "Evento",
    "search.resultType.group": "Grupo",
    "search.resultType.page": "Página",
    "search.resultType.person": "Persona",
    "search.resultType.post": "Publicar en",
    "search.resultType.product": "Producto",
    "search.resultType.job": "Trabajo",
    "search.resultType.recipe": "Receta",
    "search.search": "Buscar en",
    "search.searchBarPlaceholder": "Buscar...",
    "search.searchBarPlaceholderEntries": "Buscar el nombre o la dirección del distribuidor.",
    "search.searchBarPlaceholderEntriesPosition": "Especifica la posición para la búsqueda de radios.",
    "search.searchBarPlaceholderGeoSearch": "Introduzca su dirección...",
    "search.searchBarPlaceholderMobile": "Buscar...",
    "search.searchBarPlaceholderProducts": "Buscar productos",
    "search.searchBarPositionTooltip": "Determinar la posición o introducir la ubicación con la dirección",
    "search.showAll": "Mostrar todo",
    "social.comment": "Comentario",
    "social.Comment": "Comentario",
    "social.CopycatJoiner": "Sueña tu entrada",
    "social.dream.donateAmount": "Apoyo con una suma de dinero",
    "social.dream.donateDreampoints": "Apoyo con Dreampoints",
    "social.dream.donateInfo": "El mensaje es privado y sólo puede ser leído por el propietario de la entrada.",
    "social.dream.donateTip": "Apoyo con asesoramiento y acción",
    "social.dream.done": "DONE",
    "social.dream.edit": "Editar entrada",
    "social.dream.findDreams": "Encuentre aquí entradas pequeñas y grandes y haga realidad su entrada y/o ayude a otros a realizar sus entradas.",
    "social.dream.followedDreams": "Entradas que sigue",
    "social.dream.giveEmail": "Compartir mi dirección de correo electrónico",
    "social.dream.givePhone": "Compartir mi número de teléfono",
    "social.dream.joinerApproved": "Usted está participando en esta entrada",
    "social.dream.joinerButton": "Únase a esta entrada",
    "social.dream.joinerButtonMobile": "Únase a",
    "social.dream.joinerDream": "Sueño de grupo",
    "social.dream.joinerInfo": "Si el propietario de la entrada confirma tu participación, esta entrada se convierte en un sueño de grupo, donde tienes más derechos.",
    "social.dream.joinerMessage": "Introduzca aquí su mensaje para el propietario del anuncio:",
    "social.dream.joinerPending": "El propietario de la entrada todavía tiene que confirmar su participación",
    "social.dream.joinerSupport": "Usted apoya esta entrada",
    "social.dream.joinerSupportButton": "Apoye esta entrada",
    "social.dream.joinerSupportButtonMobile": "Soporte",
    "social.dream.joinerSupportInfo": "Estamos trabajando constantemente en mejoras, como el apoyo con dinero o los puntos de entrada. No se pierda la oportunidad.",
    "social.dream.joinerSupportPending": "El propietario de la entrada debe seguir permitiendo su apoyo",
    "social.dream.member": "Miembro",
    "social.dream.members": "Miembros",
    "social.dream.meToo": "Yo también quiero hacerlo",
    "social.dream.meTooInfo": "Muéstrale al propietario de la entrada que crees que la entrada es genial y que quieres hacer esta entrada.",
    "social.dream.noFollow": "¡No estás siguiendo ninguna entrada!",
    "social.dream.noJoiner": "Todavía no eres miembro de un sueño de grupo",
    "social.dream.noLikes": "¡Aún no te gusta ninguna entrada!",
    "social.dream.noSupport": "No apoya una entrada",
    "social.dream.registerFirstText": "Para poder compartir una entrada, apoyar la entrada o indicar que quieres hacer esta entrada, tienes que estar conectado. Inscribirse para compartir su entrada es rápido y gratuito. Sólo tiene que hacer clic en el botón \"Registro\".",
    "social.dream.registerFirstTitle": "¿Soñando, apoyando, incluso haciendo?",
    "social.dream.settings": "Ajustes de entrada",
    "social.dream.shareTitle": "Compartir el sueño",
    "social.dream.similar": "Entradas relacionadas",
    "social.dreamline.addImageToDream": "Añade esta imagen a tu entrada",
    "social.dreamline.addMedia": "Añadir imagen/vídeo",
    "social.dreamline.allActivities": "Todas las actividades de Dreamline",
    "social.dreamline.anonymous": "Anónimo",
    "social.dreamline.commentButton": "Comentario",
    "social.dreamline.comments": "escribe:",
    "social.dreamline.depublished": "Este comentario ha sido eliminado por comportamiento inapropiado.",
    "social.dreamline.dreampointsInfo": "Estos son los puntos de sueño de la entrada. Puede encontrar más información sobre Dreampoints en su perfil.",
    "social.dreamline.fewDreampoints": "tiene muy pocos puntos de sueño. Comente, guste o comparta la entrada.",
    "social.dreamline.filterBySteps": "Filtrar por pasos",
    "social.dreamline.filterComment": "Comentarios",
    "social.dreamline.filteredBy": "Actividades filtradas por",
    "social.dreamline.filteredBySteps": "Filtrado por pasos",
    "social.dreamline.filterFavorite": "Sigue",
    "social.dreamline.filterLike": "Le gusta",
    "social.dreamline.filterShare": "Acciones",
    "social.dreamline.filterStep": "Pasos",
    "social.dreamline.flagged": "Este puesto fue reportado",
    "social.dreamline.isComment": "Comentarios",
    "social.dreamline.isCopycatJoiner": "también sueña despierto",
    "social.dreamline.isFirstUpdate": "creado",
    "social.dreamline.isShare": "compartido",
    "social.dreamline.isStep": "ha dado un nuevo paso",
    "social.dreamline.isUpdate": "ACTUALIZADO",
    "social.dreamline.lastEntry": "Actualización de la última entrada",
    "social.dreamline.locked": "Este contenido sólo es visible para los compañeros de sueño",
    "social.dreamline.lockedCTA": "Únase a esta entrada ahora",
    "social.dreamline.noMessages": "Esta entrada aún no tiene actividad. Escribe el primer comentario ahora.",
    "social.dreamline.noMessagesFiltered": "No hay actividad con esta configuración de filtro en esta entrada.",
    "social.dreamline.nowFollower": "siga esta entrada!",
    "social.dreamline.nowJoiner": "es ahora un partidario",
    "social.dreamline.nowLikes": "me ha gustado esta entrada ahora!",
    "social.dreamline.nowSharing": "compartió esta entrada!",
    "social.dreamline.onlyJoinerVisible": "Sólo visible para los compañeros de sueño/apoyo",
    "social.dreamline.prefixComment": "tiene esta entrada antes de",
    "social.dreamline.prefixCopycatJoiner": "quisiera actualizar esta entrada ya que",
    "social.dreamline.prefixFavorite": "sigue a esta entrada ya que",
    "social.dreamline.prefixJoiner": "admite esta entrada ya que",
    "social.dreamline.prefixLike": "le gusta esta entrada ya que",
    "social.dreamline.prefixShare": "tiene esta entrada antes de",
    "social.dreamline.prefixStep": "tiene antes",
    "social.dreamline.prefixUpdate": "tiene esta entrada antes de",
    "social.dreamline.profile": "Perfil",
    "social.dreamline.provideImage": "Proporciono esta imagen al propietario de la entrada",
    "social.dreamline.resetFilter": "Reiniciar el filtro",
    "social.dreamline.sendComment": "Enviar",
    "social.dreamline.sharePart": "Compartir",
    "social.dreamline.toggleStream": "Comentarios",
    "social.dreamline.toggleStreamSingle": "Comentario",
    "social.dreamline.writeComment": "Publica un comentario...",
    "social.Favorite": "Siga",
    "social.follow": "Siga",
    "social.Follow": "Siga",
    "social.hasLiked": "Ya te gusta esta entrada.",
    "social.join": "Únase a",
    "social.Join": "Únase a",
    "social.Joiner": "Soñando a lo largo de",
    "social.like": "Como",
    "social.Like": "Como",
    "social.report.headlinePrefix": "Tú estás ahí",
    "social.report.headlineSuffix": "para informar.",
    "social.report.login": "Debes estar conectado para reportar la publicación.",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "Otros contenidos objetables",
    "social.report.reportScam": "Tergiversación, acoso.",
    "social.report.reportSex": "Contenido sexual",
    "social.report.reportSpam": "Esto es spam",
    "social.report.reportViolence": "Representación de la violencia",
    "social.report.send": "Enviar mensaje",
    "social.report.title": "Informe",
    "social.share": "Compartir",
    "social.Share": "Compartir",
    "syd.ui.dreamerCommercial": "Normativa B2B",
    "syd.ui.userCommercial": "Soñador B2B",
    "user.company.addUser": "Crear usuarios adicionales para",
    "user.company.inviteUser": "Enviar correo de invitación",
    "user.company.userIsInvited": "Se envió el correo de invitación",
    "user.company.userIsActive": "El usuario está activo",
    "user.company.userIsNotActive": "El usuario aún no está activado",
    "user.company.additionalWebsite": "Otro sitio web de la empresa (sólo dominio sin https://)",
    "user.company.companyName": "Nombre de la empresa",
    "user.company.contactFor": "Datos de contacto de",
    "user.company.contactForDefault": "contacto general",
    "user.company.copyContact": "Asumir los datos de contacto de los detalles de la empresa para la entrada",
    "user.company.email": "Correo electrónico de contacto",
    "user.company.facebook": "Cuenta de Facebook de la empresa (sólo el nombre de la cuenta)",
    "user.company.fax": "Número de fax para contacto por escrito",
    "user.company.google": "Cuenta de Google+ de la empresa (sólo el nombre de la cuenta)",
    "user.company.instagram": "Cuenta de Instagram de la empresa (sólo el nombre de la cuenta)",
    "user.company.linkedin": "Cuenta de LinkedIn de la empresa (sólo el nombre de la cuenta)",
    "user.company.newCustomerAccount": "Crear una cuenta de empresa",
    "user.company.noCustomerValidation": "Su cuenta de empresa aún no ha sido activada.",
    "user.company.noCustomerYet": "Su registro aún no tiene asignada una cuenta comercial.",
    "user.company.notAllowed": "No puede realizar ningún cambio porque su estado de usuario ({{type}}) no lo permite.",
    "user.company.phone": "Número de teléfono de contacto",
    "user.company.pinterest": "Cuenta de Pinterest de la empresa (sólo el nombre de la cuenta)",
    "user.company.remarks": "Notas",
    "user.company.registerNumber": "Número de registro comercial",
    "user.company.registerAuth": "Tribunal local",
    "user.company.acerCode": "Código ACER de su suministro de energía",
    "user.company.taxNumber": "Número de identificación fiscal",
    "user.company.type.default": "Estándar",
    "user.company.type.procurator": "Firmante autorizado",
    "user.company.type.manager": "Director General",
    "user.company.twitter": "Cuenta de Twitter de la empresa (sólo el nombre de la cuenta sin @)",
    "user.company.ustid": "UStID",
    "user.company.ustid.notValid": "Por favor, proporcione un número de identificación del impuesto sobre las ventas válido",
    "user.company.website": "Sitio web de la empresa (sólo dominio sin https://)",
    "user.company.xing": "Cuenta Xing de la empresa (sólo el nombre de la cuenta)",
    "user.company.youtube": "Cuenta de Youtube de la empresa (sólo el nombre de la cuenta)",
    "user.message.after-save-error": "No se han podido guardar los cambios.",
    "user.message.after-save-success": "Sus cambios se han guardado.",
    "user.message.can-not-login": "No podemos iniciar la sesión con estas entradas de usuario y contraseña. ¿Está bien escrito el nombre de usuario? ¿Has introducido la contraseña correctamente? Tal vez algo salió mal durante el registro.",
    "user.message.can-not-logout": "¡No pudimos firmar tu salida!",
    "user.message.conflict": "Este nombre de usuario ya existe, elige otro.",
    "user.message.fileExistsError": "Carga fallida. El archivo ya existe",
    "user.message.fileSizeError": "Carga fallida. El archivo es demasiado grande",
    "user.message.loginFailureBottom": "¿Quizás no tienes todavía una cuenta de entrada?",
    "user.message.loginFailureTop": "¿Está bien escrito su nombre de usuario? ¿Has introducido correctamente tu contraseña?",
    "user.message.registration-error": "Se ha producido un error durante su registro.",
    "user.message.registration-success": "Debería recibir un correo electrónico de confirmación por nuestra parte en breve.",
    "user.message.unknownError": "Error desconocido al cargar el archivo",
    "user.message.upload-success": "Carga exitosa",
    "user.message.verify-error": "Su dirección de correo electrónico no ha podido ser verificada. Póngase en contacto con el administrador.",
    "user.message.verify-success": "Su dirección de correo electrónico ha sido confirmada. Ya puedes inscribirte:",
    "user.ui.accountGreeting": "Hola,",
    "user.ui.accountMenuActivities": "Actividades",
    "user.ui.accountMenuCompanySettings": "Información de la empresa",
    "user.ui.accountMenuEntries": "Mis entradas",
    "user.ui.accountMenuEntryPre": "Entradas,",
    "user.ui.accountMenuEvents": "Mis eventos",
    "user.ui.accountMenuFavorites": "Mis favoritos",
    "user.ui.accountMenuFollows": "Entradas que sigo",
    "user.ui.accountMenuImages": "Fotos/Vídeos/Documentos",
    "user.ui.accountMenuJoin": "Mis sueños de grupo",
    "user.ui.accountMenuLikes": "Entradas que me gustan",
    "user.ui.accountMenuMeasuring": "Mis lugares y datos de medición",
    "user.ui.accountMenuMessages": "Noticias",
    "user.ui.accountMenuNewsletter": "Mis suscripciones al boletín de noticias",
    "user.ui.accountMenuPoi": "Mis ubicaciones",
    "user.ui.accountMenuPress": "Mis artículos",
    "user.ui.accountMenuProducts": "Mis productos",
    "user.ui.accountMenuJobs": "Mi trabajo ofrece",
    "user.ui.accountMenuProfile": "Perfil del usuario",
    "user.ui.accountMenuRatings": "Comentarios",
    "user.ui.accountMenuRegistrationData": "Datos de acceso",
    "user.ui.accountMenuReviews": "Comentarios",
    "user.ui.accountMenuSettings": "Ajustes",
    "user.ui.accountMenuSkillsOffer": "Estoy ofreciendo...",
    "user.ui.accountMenuSkillsRequest": "Necesito...",
    "user.ui.accountMenuOffer": "Mis ofertas",
    "user.ui.accountMenuRequest": "Mis peticiones",
    "user.ui.accountMenuSkillsSearch": "Solicitudes y ofertas",
    "user.ui.accountMenuSubscriptions": "Suscripciones",
    "user.ui.accountMenuSubscritions": "Mi suscripción",
    "user.ui.accountMenuSupport": "Entradas admitidas",
    "user.ui.accountMenuSupports": "Entradas que apoyo",
    "user.ui.accountMenuTender": "Licitaciones",
    "user.ui.accountMenuOrders": "Mis pedidos",
    "user.ui.accountNoMessages": "Todavía no has recibido ningún mensaje.",
    "user.ui.accountProgressbar": "Su perfil es {{value}}% completo",
    "user.ui.accountProgressbarEntry": "Su entrada es {{value}}% completo",
    "user.ui.accountSettings": "Otros ajustes",
    "user.ui.accountCookieSettings": "Con un clic en la opción de configuración puedes ajustar tus parámetros.",
    "user.ui.accountCookieEssential": "Esencial",
    "user.ui.accountCookieAnalysis": "Datos de análisis",
    "user.ui.accountCookieSocial": "medios de comunicación social",
    "user.ui.accountCookieReviews": "Comentarios",
    "user.ui.accountToolbarLogin": "Entrar en el sistema",
    "user.ui.accountToolbarLoginTooltip": "Regístrese gratuitamente y comience",
    "user.ui.accountToolbarLogout": "Cierre de sesión",
    "user.ui.adminPreview": "Vista previa",
    "user.ui.backToLogin": "Volver a la página de inscripción",
    "user.ui.birthday": "Cumpleaños",
    "user.ui.cannotLogin": "¿No puedes conectarte? Haga clic aquí para restablecer su contraseña.",
    "user.ui.changePassword": "Me gustaría cambiar mi contraseña",
    "user.ui.changeUserGroup": "Otra suscripción",
    "user.ui.city": "Ciudad",
    "user.ui.companyName": "Nombre de la empresa",
    "user.ui.companyNameTooltip": "Importante: Su entrada también aparecerá bajo estos nombres de empresa",
    "user.ui.country": "País",
    "user.ui.createNewAccount": "Crear una nueva cuenta de usuario",
    "user.ui.dataDelete": "utilizado para otras entradas. ¿Desea eliminar el archivo de forma permanente? Siempre que se utilice este archivo, se sustituirá por un marcador de posición.",
    "user.ui.dataUsage": "El archivo se guarda en",
    "user.ui.dataUsageNone": "El archivo no se utiliza todavía. ¿Desea eliminar el archivo de forma permanente?",
    "user.ui.deleteImage": "Borrar imagen",
    "user.ui.description": "Introduzca aquí una descripción general de la persona o empresa",
    "user.ui.documents": "Documentos",
    "user.ui.documentSearch": "Búsqueda de documentos",
    "user.ui.download": "Descargar {{title}}",
    "user.ui.email": "Dirección de correo electrónico",
    "user.ui.fillOutForm": "Para el registro, todos los campos obligatorios deben ser rellenados completamente",
    "user.ui.firstName": "Nombre",
    "user.ui.academicTitle": "Título académico",
    "user.ui.gender": "M/F",
    "user.ui.genderFemale": "mujer",
    "user.ui.genderFemaleSalutation": "La Sra.",
    "user.ui.genderMale": "Hombre",
    "user.ui.genderMaleSalutation": "El Sr.",
    "user.ui.genderDiverse": "varios",
    "user.ui.genderThird": "no se indica",
    "user.ui.housenumber": "No.",
    "user.ui.iAmCustomer": "Me gustaría crear una cuenta B2B",
    "user.ui.internalInfo": "Hay mensajes para ti. Haga clic aquí.",
    "user.ui.lastLogin": "Última conexión en",
    "user.ui.lastName": "Apellido",
    "user.ui.loginButton": "Entrar en el sistema",
    "user.ui.loginTitle": "Inicio de sesión del usuario",
    "user.ui.languageAvailable": "Disponible en tu idioma",
    "user.ui.languageMissing": "Sólo disponible en",
    "user.ui.memberSince": "Miembro desde",
    "user.ui.mobileNumber": "Número de móvil",
    "user.ui.myAddedStorage": "Fotos proporcionadas",
    "user.ui.myStorage": "Mi directorio",
    "user.ui.noRegisterMessage": "Para obtener una cuenta de usuario, diríjase a las direcciones de contacto indicadas. No puedes registrarte como usuario.",
    "user.ui.oldPassword": "Contraseña anterior",
    "user.ui.password": "Contraseña",
    "user.ui.pleaseSelect": "Por favor, seleccione",
    "user.ui.pleaseSelectSalutation": "Por favor, seleccione el saludo",
    "user.ui.registerButton": "Configurar la cuenta de usuario ahora",
    "user.ui.registerMessage": "Regístrese ahora y comience inmediatamente. Es rápido y fácil.",
    "user.ui.registerTitle": "¿Es nuevo con nosotros?",
    "user.ui.requestPasswordReset": "Solicitar una nueva contraseña y eliminar la antigua",
    "user.ui.requestPasswordSuccess": "En breve recibirás un correo electrónico con un enlace de restablecimiento.",
    "user.ui.requestPasswordError": "Error desconocido al restablecer la contraseña.",
    "user.ui.resetPassword": "Restablecer contraseña",
    "user.ui.setPassword": "Establecer contraseña",
    "user.ui.resetPasswordPage": "Restablecer la contraseña",
    "user.ui.setPasswordPage": "Establezca su contraseña",
    "user.ui.resetPasswordSuccess": "Su contraseña ha sido restablecida, ya puede iniciar sesión",
    "user.ui.setPasswordSuccess": "Se ha establecido su contraseña, ya puede iniciar sesión",
    "user.ui.selectCustomerType": "Usted es... por favor, seleccione",
    "user.ui.stayLoggedin": "Manténgase conectado",
    "user.ui.stayLoggedinInfo": "Si se trata de un dispositivo de acceso público, desmarque esta opción y vuelva a cerrar la sesión para terminar.",
    "user.ui.street": "Calle",
    "user.ui.salutation": "Saludo",
    "user.ui.title": "Título",
    "user.ui.thankYouForSubscribing": "Gracias por suscribirse a nuestro boletín.",
    "user.ui.errorAtSubscribing": "Error al suscribirse al boletín de noticias",
    "user.ui.unsubscribe": "cerrar la sesión",
    "user.ui.unsubscribePage": "¿Está seguro de que quiere darse de baja de nuestro boletín?",
    "user.ui.uploadDrop": "Para cargar, arrastre un archivo aquí o",
    "user.ui.uploadSelectFile": "seleccione un archivo",
    "user.ui.uploadToMyStorage": "Subir nuevas imágenes a mi directorio",
    "user.ui.uploadUserImage": "Seleccione la imagen del usuario",
    "user.ui.username": "Nombre de usuario (dirección de correo electrónico)",
    "user.ui.userName": "Nombre de usuario",
    "user.ui.visibilityInfo": "Consejo: ¡Tú decides lo que se muestra públicamente desde tu perfil! Haga clic en el símbolo del ojo para fijar la visibilidad de sus entradas:",
    "user.ui.zip": "Código postal",
    "user.ui.phone": "Número de teléfono",
    "user.user": "Un solo transportista",
    "user.users": "Entradas",
    "user.product.addedToFavorites": "El artículo se ha añadido a tus favoritos",
    "order.status.zero": "pedido recibido",
    "order.status.one": "En proceso",
    "order.status.two": "El pedido ha sido enviado",
    "order.status.three": "Accedido",
    "order.status.four": "Volver",
    "order.status.default": "Estado de ocupación desconocido",
    "country.germany": "Alemania",
    "country.austria": "Austria",
    "country.belgium": "Bélgica",
    "country.denmark": "Dinamarca",
    "country.bulgaria": "Bulgaria",
    "country.croatia": "Croacia",
    "country.cyprus": "Chipre",
    "country.czechia": "República Checa",
    "country.estonia": "Estonia",
    "country.finland": "Finlandia",
    "country.france": "Francia",
    "country.greece": "Grecia",
    "country.hungary": "Hungría",
    "country.ireland": "Irlanda",
    "country.italy": "Italia",
    "country.latvia": "Letonia",
    "country.lithuania": "Lituania",
    "country.luxembourg": "Luxemburgo",
    "country.lu": "Luxemburgo",
    "country.malta": "Malta",
    "country.netherlands": "Países Bajos",
    "country.poland": "Polonia",
    "country.portugal": "Portugal",
    "country.romania": "Rumanía",
    "country.slovakia": "Eslovaquia",
    "country.slovenia": "Eslovenia",
    "country.spain": "España",
    "country.sweden": "Suecia",
    "country.norway": "Noruega",
    "country.switzerland": "Suiza",
    "country.turkey": "Turquía",
    "country.unitedKingdom": "Reino Unido",
    "openingHours.tNowClosed": "Ya está cerrado",
    "openingHours.tOpeningHoursNotKnown": "no se conocen más horarios de apertura",
    "openingHours.tNowOpen": "Ahora abierto",
    "openingHours.tNowStillOpen": "Ahora sigue abierto",
    "openingHours.tNextOpeningHourUnknown": "próxima fecha de apertura desconocida",
    "openingHours.tSoonOpenFrom": "abrirá de nuevo pronto de",
    "openingHours.tFrom": "de",
    "openingHours.tUntil": "Por",
    "openingHours.tOpenAgainFrom": "abrir de nuevo desde",
    "openingHours.tOpenAgainFrom2": "abrió de nuevo el"
  };
});