define('webapp/routes/account/entries/edit', ['exports', 'webapp/utils/clone-deep', 'webapp/utils/deep-equal', 'webapp/config/environment'], function (exports, _cloneDeep, _deepEqual, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function defaultEntry(entry) {
    entry.options = entry.options || {};
    entry.address = entry.address || {};
    entry.contact = entry.contact || {};
    entry.fields = entry.fields || {};
    entry.openingHoursData = entry.openingHoursData || {
      data: {}
    };
    entry.categories = entry.categories || [];
    entry.documents = entry.documents || [];
    entry.audioVisuals = entry.audioVisuals || [];
    entry.visibleForGroups = entry.visibleForGroups || [];
    entry.descriptions = entry.descriptions || [];
    entry.images = entry.images || [];
    entry.tags = entry.tags || [];
    entry.isActive = true;
    entry.events = entry.events || [];
    entry.pois = entry.pois || [];
    entry.jobs = entry.jobs || [];
    entry.articles = entry.articles || [];
    entry.translations = entry.translations || {};

    return entry;
  }

  exports.default = Ember.Route.extend({
    widgetInteractions: Ember.inject.service(),
    viewService: Ember.inject.service(),
    userProxy: Ember.inject.service(),
    myStorage: Ember.inject.service(),
    message: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    i18n: Ember.inject.service(),
    media: Ember.inject.service(),

    initialEntry: null,
    initialPerson: null,
    enableTransitionCheck: true,

    onBeforeUnload: function onBeforeUnload(e) {
      if (this.modelIsDirty()) {
        var message = this.get('message');
        var messageString = 'Ungespeicherte Änderungen.';
        message.info(messageString);
        e.returnValue = messageString;
        return messageString;
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.onBeforeUnload = this.onBeforeUnload.bind(this);
    },
    activate: function activate() {
      if (_environment.default.dirtyModelCheck) {
        window.addEventListener('beforeunload', this.onBeforeUnload);
      }

      var controller = this.controllerFor(this.routeName);
      this.get('widgetInteractions').registerHandler('removeImage', controller.removeMainImage, controller);
      this.get('widgetInteractions').registerHandler('editImage', controller.editMainImage, controller);

      return this._super.apply(this, arguments);
    },
    deactivate: function deactivate() {
      if (_environment.default.dirtyModelCheck) {
        window.removeEventListener('beforeunload', this.onBeforeUnload);
      }

      this.get('widgetInteractions').unregisterHandler('removeImage');
      this.get('widgetInteractions').unregisterHandler('editImage');

      return this._super.apply(this, arguments);
    },
    beforeModel: function beforeModel() {
      var controller = this.controllerFor(this.routeName);
      var myStorage = this.get('myStorage');

      return Ember.RSVP.all([myStorage.category.ajaxGet({ param: 'json' }).then(function (cats) {
        controller.set('availableCategories', cats);
      }), myStorage.customer.ajaxGet().then(function (customer) {
        controller.set('customer', customer);
      })]);
    },
    model: function model(param) {
      var _this = this;

      var myStorage = this.get('myStorage');
      var controller = this.controllerFor(this.routeName);
      var id = param.id;
      var isNew = this.isNew = id === 'new';
      controller.set('isNew', isNew);

      this.set('enableTransitionCheck', true);

      if (isNew) {
        this.set('initialEntry', {});
        this.set('initialPerson', {});
        controller.set('person', {});
        var entry = defaultEntry({});
        this.set('viewService.editedEntry', entry);
        return entry;
      } else {
        return myStorage.entryAdmin.ajaxGet({ param: id }).then(function (entry) {
          entry = defaultEntry(entry);

          var person = Ember.get(entry, 'people.0.person') || {};
          person.contact = person.contact || {};
          person.address = person.address || {};

          controller.set('person', person);

          _this.set('initialEntry', (0, _cloneDeep.default)(entry));
          _this.set('initialPerson', (0, _cloneDeep.default)(person));

          _this.set('viewService.editedEntry', entry);
          return entry;
        });
      }
    },
    save: function save() {
      var _this2 = this;

      var controller = this.controllerFor(this.routeName);
      var model = Ember.assign({}, controller.get('model')); // clone model
      var myStorage = this.get('myStorage');
      var message = this.get('message');
      var person = controller.get('person');

      // bodystreet only, reset jobReplyMail if not matching bodystreet
      var jobReplyMail = Ember.get(model, 'fields.jobReplyMail');
      if (jobReplyMail && !jobReplyMail.match('^.*@bodystreet\\.\\w{2,}$')) {
        Ember.set(model, 'fields.jobReplyMail', '');
      }

      var promise = Ember.RSVP.resolve();
      if (person._id) {
        // update person
        // promise = myStorage.personAdmin.ajaxPut({ param: person._id, data: person });
      } else {
          // create person
          // promise = myStorage.personAdmin.ajaxPost({ data: person });
        }

      return promise.then(function (person) {
        if (person) {
          if (model.people && model.people.length) {
            Ember.set(model, 'people.0.person', person);
          } else {
            model.people = [{ person: person }];
          }
        }
      }).catch(function (err) {
        console.error(err.stack);
        message.error('Fehler beim Anlegen/Speichern der Person: ' + (err.payload && err.payload.message));
        throw err;
      }).then(function () {
        if (_this2.isNew) {
          var data = Ember.assign({}, model);
          data.owner = _this2.get('userProxy._id');
          return myStorage.entryAdmin.ajaxPost({ data: data, query: { autoSlug: true } });
        } else {
          return myStorage.entryAdmin.ajaxPut({ param: model._id, data: model });
        }
      }).then(function (res) {
        message.info('Eintrag gespeichert');
        _this2.set('enableTransitionCheck', false);
        _this2.set('viewService.skipScroll', true);

        if (_this2.isNew) {
          _this2.replaceWith(_this2.routeName, res._id);
        } else {
          _this2.refresh();
        }
      }).catch(function (err) {
        console.error(err.stack);
        message.error('Fehler beim speichern: ' + (err.payload && err.payload.message));
      });
    },
    modelIsDirty: function modelIsDirty() {
      var controller = this.controllerFor(this.routeName);
      var initialEntry = this.get('initialEntry');
      var initialPerson = this.get('initialPerson');
      var entry = controller.get('model');
      var person = controller.get('person');

      var modelsEqual = (0, _deepEqual.default)(entry, initialEntry) && (0, _deepEqual.default)(person, initialPerson);
      return !modelsEqual;
    },


    actions: {
      willTransition: function willTransition(transition) {
        var _this3 = this;

        var i18n = this.get('i18n');
        var enableTransitionCheck = this.get('enableTransitionCheck');
        var isFastBoot = this.get('fastboot.isFastBoot');
        if (_environment.default.dirtyModelCheck && enableTransitionCheck) {
          if (this.modelIsDirty()) {
            transition.abort();
            //TODO: add i18n
            if (!isFastBoot) {
              UIkit.modal.confirm(i18n.t('application.ui.unsavedChanges').toString(), {
                labels: {
                  cancel: this.get('media.isMobile') ? i18n.t('application.ui.discardUnsavedChangesMobile').toString() : i18n.t('application.ui.discardUnsavedChanges').toString(),
                  ok: i18n.t('application.ui.saveUnsavedChanges').toString()
                }
              }).then(function () {
                _this3.save();
              }).catch(function () {
                _this3.set('enableTransitionCheck', false);
                transition.retry();
              });
            }
          }
        }
      },
      save: function save() {
        this.save();
      }
    }
  });
});