define('webapp/services/gdpr-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    cachedData: Ember.inject.service(),
    cmsService: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    gdprStatus: 'pending', // pending, denied accepted

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('fastboot.isFastBoot')) {
        this.set('gdprClicked', false);
      } else {
        if (navigator.userAgent && /googlebot/i.test(navigator.userAgent)) {
          this.set('gdprStatus', 'denied');
          return;
        }
        var gdprStatus = localStorage.getItem('gdprStatus') || 'pending';
        this.set('gdprStatus', gdprStatus);
      }
    },
    acceptGDPR: function acceptGDPR() {
      var _this = this;

      this.set('gdprStatus', 'accepted');
      window.localStorage.setItem('gdprStatus', 'accepted');
      var cmsService = this.get('cmsService');
      cmsService.setSiteCodes(this.get('cachedData.cachedSite'));
      Ember.run.later(function () {
        _this.trigger('gdprAccepted');
      }, 500);
    },
    denyGDPR: function denyGDPR() {
      this.set('gdprStatus', 'denied');
      window.localStorage.setItem('gdprStatus', 'denied');
    }
  });
});