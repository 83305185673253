define('webapp/locales/de/translations', ['exports', 'webapp/locales/de/translations-base'], function (exports, _translationsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    'user.ui.accountToolbarLoginTooltip': 'Anmeldung zum Händlershop',
    'application.ui.navigateOneLevelUp': "Zurück",
    'search.searchBarPlaceholderMobile': "Produksuche...",
    'application.ui.back': 'zurück',
    'search.searchBarPlaceholderJobs': "Suchen Sie nach Jobs oder in deren Beschreibung. ",
    'search.searchBarPlaceholderEvents': "Suchen Sie nach Terminen oder Veranstaltungen. ",
    'search.searchBarPlaceholderDefault': "Suche... ",
    'search.searchBarPlaceholderGeoSearch': 'Ihr Standort...',
    'search.backToResults': 'Zurück zur Ergebnisliste',
    'noResults': 'Keine Ergebnisse',
    'blog.ui.noCategory': 'Blogbeitrag',
    'widgets.categoryFilter.reset': 'Filter zurücksetzen',
    'widgets.shoppingCart.wishlist': 'Merk- und Wunschliste',
    'widgets.shoppingCart.wishlist.empty': 'Sie haben noch keine Produkte auf Ihrer Merk- und Wunschliste',
    'widgets.shoppingCart.cart': 'Warenkorb',
    'entries.entry.ui.products.allCategories': 'Alle Produkte anzeigen',
    'entries.entry.ui.products.detail': 'Variante auswählen',
    'entries.entry.ui.products.notOrderable': 'kann derzeit nicht bestellt werden.',
    'entries.entry.ui.products.checkOutShow': 'Warenkorb anzeigen',
    'entries.entry.ui.products.checkOut': 'Weiter zur Kasse',
    'entries.entry.ui.products.chooseVariant': 'Bitte wählen Sie eine Variante aus',
    'entries.entry.ui.products.chooseVariantMobile': '← auswahl',
    'entries.entry.ui.products.notAvailable': 'nicht verfügbar',
    'entries.entry.ui.products.crosselling.title': 'Produkte, die Sie interessieren könnten',
    'entries.entry.ui.products.relatedArticles.title': 'Weiterführende Informationen zu diesem Produkt',
    'entries.entry.ui.products.delivery.red': 'Produkt zur Zeit nicht lieferbar',
    'entries.entry.ui.products.delivery.yellow': 'Produkt im Zulauf. Eventuell längere Lieferzeit.',
    'entries.entry.ui.products.delivery.green': 'Produkt in ausreichender Stückzahl auf Lager',
    'entries.products.ui.combinationNotAvailable': 'Produkt in dieser Kombination nicht verfügbar',
    'search.searchBarPlaceholder': 'Suche nach...',
    'product.ui.content': 'Inhalt',
    'product.ui.ingredients': 'Inhalt',
    'product.ui.ingredientsFood': 'Zutaten',
    'product.ui.storage': 'Aufbewahrungshinweise',
    'product.ui.taste': 'Geschmack',
    'product.ui.origin': 'Bee-Happy-Garantie',
    'product.ui.dosage': 'Einnahme-Empfehlung',
    'product.ui.application': 'Anwendungs-Empfehlung',
    'product.ui.misc': 'Sonstige Information',
    'product.ui.technicalData': 'Technische Daten und weitere Infos',
    'product.ui.pcs': 'Stück',
    'product.ui.bought': 'gekauft',
    'product.ui.boughtLatest': ' zuletzt',
    'product.ui.boughtPre': 'Sie haben dieses Produkt',
    'product.ui.collect': 'Jetzt hier bestellen und an diesen Verkaufsstellen abholen',
    'product.ui.crosselling': 'Das könnte Sie auch interessieren',
    'product.ui.crossellingReverse': 'Kunden, die sich für dieses Produkt interessiert haben, kauften auch',
    'product.ui.ingredientsBio': 'Zutaten aus kontrolliert biologischem Anbau',
    'product.ui.multipleSellingUnits': 'In mehreren Größen erhältlich',
    'product.ui.information': 'Weiterführende Informationen',
    'product.ui.ecoCertText': 'BIO-Zertifizierung',
    'product.ui.deliveryInfo': 'Lieferhinweis',
    'product.ui.cartEmpty': 'Sie haben noch keine Artikel in Ihrem Warenkorb.',
    'product.ui.productOverview': 'Zur Übersicht der angebotenen B2B-Shop-Produkte.',
    'article.ui.noCategory': 'News',
    'article.ui.noCategoryProduct': 'Wissenswertes',
    'category.ui.field.leasingWeekDuration': 'Mietdauer',
    'category.ui.field.weeks': 'Wochen',
    'category.ui.field.pcs': 'Stück',
    'category.ui.field.productSize': 'Größe',
    'category.ui.field.material': 'Material',
    'category.ui.field.%': '%',
    'user.ui.registerMessage': 'Sie können in unseren Shop nur als Geschäftskunde bestellen. <br><br>Wenn Sie ein Gewerbebetrieb oder Einkäufer sind, können Sie hier ein Geschäftskundenkonto anlegen und von vielen Vorteilen und Informationen profitieren. <br><br>Beachten Sie bitte, dass für ein Geschäftskonto Unterlagen notwendig sind.',
    'page.ui.privacy': 'Datenschutz',
    'page.ui.terms': 'Allgemeinen Geschäftsbedingungen (AGB)',
    'page.ui.revocation': 'Widerrufsbedingungen',
    'checkout.delivery.deliveryDate': 'und voraussichtlich geliefert am ',
    'checkout.delivery.standard': 'Lieferung bis ',
    'checkout.delivery.express': 'Express-Lieferung bis ',
    'checkout.delivery.pickUp': 'Vor Ort abholen',
    'checkout.delivery.expressNotPossible': 'Sie haben eine Rechnungs- oder Lieferadresse angegeben, mit der kein Expressversand möglich ist.',
    'checkout.summary.withAccount': 'Sie bestellen mit den Kundenkonto-Einstellungen von ',
    'checkout.cart.checkoutWithAccount': 'Zur Kasse mit meinen Kontodaten',
    'checkout.cart.customerLocked': 'Ihr Kundenkonto wurde gesperrt. Bitte wenden sie sich an den Support.',
    'checkout.cart.additionalCosts': 'zusätzliche Kosten und Rabatte',
    'checkout.cart.delete': 'Warenkorb löschen',
    'checkout.cart.promocode': 'Promo-Code:',
    'checkout.cart.customerComment': 'Kundenkommentar',
    'checkout.cart.addPosition': 'Angebotsposition hinzufügen',
    'checkout.cart.convertOffer': 'in Angebot umwandeln',
    'checkout.order.comment': 'Ihre Anmerkung zur Bestellung',
    'checkout.promotion.info': 'Haben Sie einen Promotioncode aus einer unserer Aktionen, den Sie für diese Bestellung einlösen wollen?',
    'checkout.promotion.notMinValue': 'Der Mindestbestellwert von € {{minValue}} für den aktuellen Promo-Code ist noch nicht erreicht.',
    'checkout.promotion.placeholder': 'Ihr Promotioncode',
    'checkout.promotion.invalid': 'Der eingegebene Promotionode ist nicht (mehr) gültig',
    'checkout.promotion.redeem': 'Einlösen',
    'checkout.cart.customerCommentInfo': 'Sollten Sie eine Anmerkung zur Bestellung haben, geben Sie in Ihrer Anmerkung bitte eine zusätzliche telefonische Kontaktadresse an.',
    'checkout.checkout.status': 'Zahlungs-/Versand-Status',
    'account.order.rebuy': 'Erneut bestellen',
    'account.ui.company': 'Kontoangaben',
    'account.company.address.isDefault': 'Diese Adresse ist meine Standardadresse',
    'account.ui.companyContact': 'Firmenkontakt',
    'account.company.contactName': 'Ansprechpartner',
    'account.ui.companyWant': 'Ich möchte als Geschäftskunde akkreditiert werden',
    'account.ui.company.uploadedDocuments': 'Ihre hochgeladenen Dokumente',
    'account.ui.company.uploadedDocuments.info': 'Bitte nur PDF, JPG oder PNG hochladen.',
    'account.ui.companyType': 'Unternehmenstyp',
    'account.ui.company.upload': 'Laden Sie bitte die erforderlichen Dokumente als PDF, JPG oder PNG hoch.',
    'account.ui.company.upload.Wiederverkäufer': 'Als Wiederverkäufer benötigen wir von Ihnen ein FOTO Ihrer bisherigen Kundennummer bei RING oder ein Bild oder SCAN Ihrer Gewerbeanmeldung.',
    'account.ui.company.upload.Einkäufer': 'Bitte laden Sie ein Bild Ihrer Visitenkarte hoch',
    'account.ui.company.upload.Heilpraktiker': 'Bitte laden Sie ein Bild Ihrer Visitenkarte hoch',
    'account.ui.company.upload.Apotheker': 'Bitte laden Sie ein Bild Ihrer Visitenkarte hoch',
    'account.ui.company.upload.Sonstiges': 'Bitte laden Sie ein Bild Ihrer Visitenkarte hoch',
    'account.ui.makeDeliveryAddress': 'Abw. Lieferadresse',
    'country.DE': 'Inland',
    'country.EU': 'EU',
    'country.NONEU': 'Weltweit',
    'account.ui.company.procurement': 'Einkäufer',
    'account.ui.company.reseller': 'Wiederverkäufer',
    'account.ui.company.else': 'Sonstiges',
    'checkout.checkout.orderConsentPre': 'Mit der Bestellung erklären Sie sich mit unseren ',
    'register.email.info': 'Bitte beachten Sie, dass Ihre Benutzer-Email-Adresse gleichzeitig die Email-Adresse ist, an die die Rechnung versendet wird.'
  });
});