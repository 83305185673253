define('webapp/controllers/account/jobs/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    availableTemplateJobs: Ember.computed('templateJobs', 'model', function () {
      var model = this.get('model');
      var templateJobs = this.get('templateJobs');
      var duplicatesOf = model.map(function (item) {
        return item.duplicateOf;
      }).filter(Boolean);

      return templateJobs.filter(function (templateJob) {
        return !duplicatesOf.includes(templateJob._id);
      });
    })
  });
});